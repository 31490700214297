<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title text-center">Mein Konto</h1>
      </div>
    </div>
    <div class="row logreg">
      <div class="col-sm-12 col-md-6 mb-4 mb-sm-0">
        <div class="account-login">
          <h3>Mit Kundenkonto anmelden</h3>
          <div class="">
            <form @submit.prevent="submitForm">
              <div class="field">
                <label class="form-label">E-Mail</label>
                <div class="control">
                  <input type="text" class="form-input" v-model="username" />
                </div>
              </div>
              <div class="field">
                <label class="form-label">Passwort</label>
                <div class="control">
                  <input type="password" class="form-input" v-model="password" />
                </div>
              </div>
              <div class="notification is-danger" v-if="error">
                <p>
                  {{ error }}
                </p>
              </div>
              <router-link to="/password-reset"> Passwort vergessen?</router-link>
              <button class="login">Anmelden</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="account-register">
          <h3 class="mb-3">Ich habe noch kein Konto</h3>
          <div class="bold mb-3">
            Sie möchten ein Kundenkonto eröffnen und Kundenvorteile nutzen?
          </div>
          <div>Legen Sie jetzt in wenigen Schritten ein Konto an</div>
          <button class="register">
            <router-link to="/sign-up">Als Firmenkunde registrieren</router-link>
          </button>
        </div>
      </div>
    </div>
    <div class="page-log-in">
      <div class="columns">
        <div class="column is-4 is-offset-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LogIn",
  data() {
    return {
      username: "",
      password: "",
      error: "",
    };
  },
  mounted() {
    document.title = "Login - scanner4you";
  },
  methods: {
    async linkCart() {
      const payload = {
        "token": this.$store.state.token,
      }
      await axios
        .put(`/api/v1/cart/${this.$store.state.cartToken}/` , payload, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then((response) => {
          this.cart = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async submitForm() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      const formData = {
        username: this.username,
        password: this.password,
      };
      await axios
        .post("/api/v1/token/login/", formData)
        .then((response) => {
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          this.linkCart()
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);
          const toPath = this.$route.query.to || "/cart";
          this.$router.push(toPath);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.error = `${property}: ${error.response.data[property]}`;
            }
          } else {
            this.error = "Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00).";
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>