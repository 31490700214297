<template>
  <div class="container">
    <div class="row">
      <div class="col-12" v-if="errors.length">
        <h1 class="page-title text-center">Aktivierung fehlgeschlagen</h1>
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        <div class="d-flex justify-content-center">
          <img class="contact-icon" src="../assets/icons/phone.svg" />
          <img class="contact-icon" src="../assets/icons/mail.svg" />
        </div>
      </div>
      <div class="col-12" v-else>
        <h1 class="page-title text-center">Vielen Dank!</h1>
        <p class="text-center">Ihr Konto wurde erfolgreich verifiziert.</p>
        <div class="d-flex justify-content-center mb-3">
          <router-link to="/">
            <button class="select-page">Startseite</button>
          </router-link>
          <router-link to="/cart">
            <button class="select-page">Warenkorb</button>
          </router-link>
          <router-link to="/cart/checkout">
            <button class="select-page">Checkout</button>
          </router-link>
        </div>
      </div>
    </div>
    <Toast
          v-bind:title="toasttitle"
          v-bind:body="toastbody"
        />
  </div>
</template>

<script>
import axios from "axios";
import Toast from "@/components/Toast.vue";
export default {
  name: "SignUp",
  data() {
    return {
      errors: [],
      toasttitle: "scanner4you",
      toastbody: "",
    };
  },
  methods: {
    activateAccount() {
      const postData = {
        uidb64: new URL(location.href).searchParams.get("uidb64"),
        token: new URL(location.href).searchParams.get("token"),
      };
      axios
        .post("/api/v1/activate/", postData)
        .then((response) => {
          this.toastbody = "Ihr Konto wurde erfolgreich erstellt."
          $('.toast').toast('show');
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00).");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
  beforeMount() {
    this.activateAccount();
  },
};
</script>