import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import PurchaseProductDetail from '../views/PurchaseProductDetail.vue'
import RentalProductDetail from '../views/RentalProductDetail.vue'
import AccessoryDetail from '../views/AccessoryDetail.vue'
import SoftwareServiceDetail from '../views/SoftwareServiceDetail.vue'
import MietenList from '../views/MietenList.vue'
import KaufenList from '../views/KaufenList.vue'
import ZubehoerList from '../views/ZubehoerList.vue'
import SoftwareServiceList from '../views/SoftwareServiceList.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import AccountEdit from '../views/AccountEdit.vue'
import Order from '../views/Order.vue'
import Checkout from '../views/Checkout.vue'
import Success from '../views/Success.vue'
import Schulen from '../views/Schulen.vue'
import ActivationPending from '../views/ActivationPending.vue'
import ActivationResult from '../views/ActivationResult.vue'
import PasswordReset from '../views/PasswordReset'
import PasswordResetConfirm from '../views/PasswordResetConfirm'
import Impressum from '../views/Impressum.vue'
import DataPrivacy from '../views/DataPrivacy.vue'
import TermsConditions from '../views/TermsConditions.vue'
import FAQ from '../views/FAQ.vue'
import RegistrationConfirmation from '../views/RegistrationConfirmation.vue'
import ImpersonateUser from '../views/ImpersonateUser.vue'
import LaunchHome from '../views/LaunchHome.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: MietenList
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/schulen',
        name: 'Schulen',
        component: Schulen
    },
    {
        path: '/kaufen/:product_slug/',
        name: 'PurchaseProductDetail',
        component: PurchaseProductDetail
    },
    {
        path: '/mieten/:product_slug/',
        name: 'RentalProductDetail',
        component: RentalProductDetail
    },
    {
        path: '/kaufen/zubehoer/:product_slug/',
        name: 'AccessoryDetail',
        component: AccessoryDetail
    },
    {
        path: '/kaufen/software-service/:product_slug/',
        name: 'SoftwareServiceDetail',
        component: SoftwareServiceDetail
    },
    {
        path: '/mieten',
        name: 'Mieten',
        component: MietenList
    },
    {
        path: '/kaufen/zubehoer',
        name: 'Zubehör',
        component: ZubehoerList
    },
    {
        path: '/kaufen/software-service',
        name: 'Softwarwe & Service',
        component: SoftwareServiceList
    },
    {
        path: '/kaufen',
        name: 'Kaufen',
        component: KaufenList
    },
    {
        path: '/ueberuns',
        name: 'Über uns',
        component: Home
    },
    {
        path: '/search',
        name: 'Search',
        component: Search
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart
    },
    {
        path: '/cart/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/cart/success',
        name: 'Success',
        component: Success,
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp
    },
    {
        path: '/activation/result',
        name: 'ActivationResult',
        component: ActivationResult
    },
    {
        path: '/activation',
        name: 'ActivationPending',
        component: ActivationPending
    },
    {
        path: '/log-in',
        name: 'LogIn',
        component: LogIn
    },
    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/password/reset/confirm',
        name: 'PasswordResetConfirm',
        component: PasswordResetConfirm
    },
    {
        path: '/account',
        name: 'MyAccount',
        component: MyAccount,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/account/edit',
        name: 'AccountEdit',
        component: AccountEdit,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/order',
        name: 'Order',
        component: Order,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: DataPrivacy
    },
    {
        path: '/agb',
        name: 'Allgemeine Geschäftsbedingungen',
        component: TermsConditions
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ
    },
    {
        path: '/anmeldung/bestaetigung',
        name: 'Anmeldebestätigung',
        component: RegistrationConfirmation
    },
    {
        path: '/impersonate',
        name: 'Benutzer imitieren',
        component: ImpersonateUser
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
      },
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
        next({ name: 'LogIn', query: { to: to.path } });
    } else {
        next()
    }
})

export default router