<template>
  <div class="d-flex spinner justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Inhalt werden geladen</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    body: String,
  },
};
</script>

<style>

</style>
