<template>
  <div class="schulen">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="page-title">Datenschutzerklärung</h1>
          </div>
        </div>
        <div class="col-lg-8 page-content">
          <strong>I. Allgemeines</strong>
          <p>
            In der vorliegenden Datenschutzerklärung erkläre wir, Scanner4you c/o Vermflex GmbH („Vermflex“), wie wir
            Personendaten erheben und bearbeiten. Zudem klären wir betroffene Personen mit dieser Datenschutzerklärung
            über die ihnen zustehenden Rechte auf.
          </p>
          <strong>II. Kontakt des für die Datenbearbeitung Verantwortlichen</strong>
          <p>
            Scanner4you c/o Vermflex GmbH<br>
            Bionstrasse 3<br>
            9105 St. Gallen<br>
            Schweiz<br>
            Mail: info@scanner4you.ch<br>
            <br>
            Datenschutzrechtliche Anliegen können Sie uns an diese Kontaktadresse zukommen lassen.
          </p>
          <strong>III. Geltungsbereich dieser Datenschutzerklärung </strong>
          <p>
            Diese Datenschutzerklärung gilt für die Bearbeitung personenbezogener Daten durch die Vermflex, insbesondere
            im Zusammenhang mit ihrer Website und ihren sonstigen Angeboten und Leistungen.
          </p>
          <p>
            Unter personenbezogenen Daten werden alle Angaben verstanden, die sich auf eine bestimmte oder bestimmbare
            Person beziehen. Die Bearbeitung umfasst jeden Umgang mit personenbezogenen Daten, insbesondere das
            Aufbewahren, Bekanntgeben, Beschaffen, Erheben, Löschen, Speichern, Verändern, Vernichten und Verwenden.
          </p>
          <strong>IV. Rechte</strong>
          <p>
            Sie haben das <strong>Recht auf Auskunft</strong>. Dies betrifft die Information, ob wir Sie betreffende
            Personendaten
            bearbeiten. Falls dies der Fall ist, können Sie Informationen verlangen bezüglich alle Sie betreffenden
            Daten inkl. der verfügbaren Angaben über die Herkunft dieser Daten, den Zweck und gegebenenfalls die
            Rechtsgrundlagen des Bearbeitens sowie der Kategorien der bearbeiteten Personendaten, der an der Sammlung
            Beteiligten und der Datenempfänger.
          </p>
          <p>Sie haben das <strong>Recht auf Berichtigung</strong>. Sie können Sie betreffende Personendaten berichtigen
            oder
            vervollständigen lassen.
          </p>
          <p>Weiter haben Sie in den vom Gesetz vorgesehenen Fälle das <strong>Recht auf Löschung</strong> dieser Daten,
            z.B. wenn der
            Bearbeitungszweck nicht mehr gegeben ist oder Sie einer zukünftigen Bearbeitung der Daten widersprechen. Das
            Recht auf Löschung der Daten gilt nicht, wenn vertragliche oder gesetzliche Verpflichtungen einer Löschung
            entgegenstehen. Ein Widerruf der Zustimmung wirkt erst für die Zukunft, Bearbeitungen die vor dem Widerruf
            erfolgt sind, sind davon nicht betroffen.
          </p>
          <p>Bei Datenbearbeitungen, welche mit der Einwilligung des Kunden erfolgen, hat dieser jederzeit die
            Möglichkeit, seine <strong>Einwilligung zu widerrufen</strong>.
          </p>
          <strong>V. Erhebung und Bearbeitung von Personendaten</strong><br>
          <strong>Welche personenbezogenen Daten bearbeiten wir? Woher beziehen wir diese?</strong>
          <p>Wir bearbeiten hauptsächlich jene personenbezogenen Daten, die Sie uns bekanntgeben (z.B. an unsere
            E-Mail-Adresse, telefonisch oder auf andere Art und Weise). Es sind dies z.B. Namen, Vornamen, Adressen,
            E-Mail-Adressen, Telefonnummer, Geburtsdatum, Geschlecht, etc. Diese Personendaten werden ausdrücklich
            freiwillig bekanntgegeben.
          </p>
          <p>Zudem werden beim Besuch unserer Websites z.T. Daten erfasst (genauere Informationen hierzu gibt es weiter
            unten). Überdies bearbeiten wir, soweit erlaubt und für uns erforderlich, Daten aus öffentlichen Quellen
            (z.B. Grundbuch, Betreibungsregister, Handelsregister, Presse, Internet etc.).
          </p>
          <strong>Zweck der Datenbearbeitung und Rechtsgrundlagen</strong>
          <p>Wir bearbeiten Ihre Personendaten zunächst zur Erfüllung des Zwecks, zu dem Sie uns Ihre Daten überlassen
            haben. Wir verwenden die von uns erhobenen Personendaten in erster Linie, um unsere Verträge mit unseren
            Kunden abzuschliessen und abzuwickeln.
          </p>
          <p>Darüber hinaus bearbeiten wir Ihre Daten auch zu Werbezwecken und zur Weiterentwicklung unserer Tätigkeit
            und unserer Angebote sowie zur Einhaltung gesetzlicher Anforderungen und interner Vorschriften, zur
            Begründung, Ausübung und/oder Abwehr tatsächlicher oder potenzieller Rechtsansprüche, Untersuchungen oder
            ähnlicher Verfahren und für andere rechtmässige Zwecke, wenn sich diese Verarbeitung aus den Umständen
            ergibt oder zum Zeitpunkt der Erhebung angegeben war.
          </p>
          <p>Wir bearbeiten Ihre Personendaten basierend auf folgenden Rechtsgrundlagen:
          </p>
          <ul>
            <li>Aufgrund Ihrer Einwilligung, die Sie jederzeit widerrufen können</li>
            <li>Um einen mit Ihnen geschlossenen Vertrag zu Erfüllen oder um einen Vertrag mit Ihnen zu schliessen</li>
            <li>Um einer gesetzlichen Verpflichtung nachzukommen</li>
            <li>Zur Wahrung unserer berechtigten Interessen</li>
          </ul>
          <strong>Weitergabe von Personendaten</strong>
          <p>Ihre Daten werden nur im Rahmen des Bearbeitungszwecks an Dritte, welche auf vertraglicher oder
            gesetzlicher Basis zur Einhaltung der anwendbaren datenschutzrechtlichen Bestimmungen verpflichtet sind,
            weitergegeben. Im Übrigen können Daten an Behörden herausgegeben werden, wenn eine gesetzliche Pflicht
            besteht oder dies angezeigt erscheint. Empfänger Ihrer Daten können z.B. IT-Provider sein.
          </p>
          <p>Personendaten werden nur ins Ausland weitergegeben, wenn Sie hierzu ausdrücklich eingewilligt haben, wenn
            die Bekanntgabe in unmittelbarem Zusammenhang mit dem Abschluss oder der Abwicklung eines Vertrages steht
            oder bei Vorliegen eines anderen Grundes nach Art. 17 DSG.
          </p>
          <strong>Wie lange werden Ihre Personendaten aufbewahrt?</strong>
          <p>Ihre Daten werden so lange aufbewahrt, wie dies zur Erfüllung des Bearbeitungszwecks, für den sie erhoben
            wurden, oder gemäss gesetzlichen Vorschriften erforderlich ist.</p>
          <strong>VI. Nutzung unserer Website</strong><br>
          <p>Wir hosten unsere Website bei <a href="https://www.digitalocean.com/" target="_blank">DigitalOcean</a> am Standort Frankfurt.</p>
          <strong>Verschlüsselung</strong>
          <p>Unsere Website verwendet eine SSL-Verschlüsselung. Diese verschlüsselte Verbindung erkennen Sie daran, dass
            die Adresszeile des Browsers von http:// auf https:// wechselt und an dem Schloss-Symbol in der
            Browserzeile. Dabei werden Daten bei der Übertragung zwischen Ihrem Rechner und unserem Server mittels
            diesem SSL-Protokoll verschlüsselt.</p>
          <strong>Cookies</strong>
          <p>Wir nutzen auf unseren Websites auch Cookies. Cookies sind Textdateien, die Ihr Webbrowser beim Besuch
            unserer Website automatisch auf Ihrem Rechner speichert. Besuchen Sie unsere Website erneut, so können wir
            Sie Ihrem letzten Besuch zuordnen und Sie so quasi „wiedererkennen“. Es gibt Session Cookies, die
            automatisch gelöscht werden, wenn Sie Ihren Browser schliessen und permanente Cookies, die für eine
            bestimmte Zeit auf Ihrem Gerät gespeichert bleiben. Permanente Cookies verwenden wir, um Ihre
            Benutzereinstellungen zu speichern und um das Nutzerverhalten besser zu verstehen.</p>
          <p>In Ihren Browsereinstellungen haben Sie die Möglichkeit, Cookies nicht zu akzeptieren. Sperren Sie Cookies,
            so ist es möglich, dass gewisse Teile unserer Website nicht vollständig funktionieren.</p>
          <strong>Google Analytics</strong>
          <p>Wir verwenden Google Analytics (Anbieter: Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043,
            USA), Facebook-Pixel (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland)
            und Hotjar (Hotjar Ltd., 3 Lyons Range, 20 Bisazza Street, Sliema SLM 1640, Malta), um besser zu verstehen,
            wie die Besucher unsere Website verwenden. Diese Dienstleister arbeiten mit Cookies.</p>
          <p>Die von Google Analytics erhobenen Informationen betreffend Nutzung unserer Website werden in der Regel an
            einen Server von Google in den USA übertragen und dort gespeichert. Google analysiert die Nutzung unserer
            Website, erstellt Reports über die Websiteaktivitäten und erbringt weitere mit der Websitenutzung verbundene
            Dienstleistungen. Wir haben die Funktion IP-Anonymisierung aktiviert. Deshalb wird Ihre IP-Adresse vor der
            Übermittlung in die USA gekürzt. In Ausnahmefällen findet die Kürzung erst in den USA statt. Ihre IP-Adresse
            wird nicht mit anderen Daten von Google zusammengeführt. Möchten Sie der Datenbearbeitung durch Google
            Analytics widersprechen, können Sie unter dem nachfolgenden Link ein Browser-Add-On herunterladen und
            installieren (https://tools.google.com/dlpage/gaoptout).</p>
          <strong>Server-Logdateien</strong>
          <p>Wenn Sie unsere Website besuchen, können über Ihren Browser die folgenden Daten übertragen und temporär in
            einer Server-Logdatei gespeichert werden, um Benutzerstatistiken zu erstellen: Browsertyp und
            Browserversion, verwendetes Betriebssystem, Herkunftslink bzw. Herkunfts-URL, aufgerufene Seiten / Dateien,
            Datum und Uhrzeit der Serveranfrage, verwendete Suchmaschine inkl. genutzter Suchwörter,
            Internet-Protokoll-Adresse (IP-Adresse).</p>
          <strong>Server-Logdateien</strong>
          <p>Lassen Sie uns per E-Mail-Anfragen zukommen, werden die von Ihnen gemachten Angaben zum Zweck der
            Bearbeitung Ihrer Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. </p>
          <p>Die Kommunikation per E-Mail erfolgt nicht über eine gesicherte Datenverbindung. Bitte lassen Sie uns
            deshalb per E-Mail keine vertraulichen Informationen zukommen.</p>
          <strong>Drittanbieter, Links und Plug-ins</strong>
          <p>Die vorliegende Website bietet Ihnen die Möglichkeit, mit anderen Websites zu interagieren, die nicht
            Vermflex gehören. Bitte seien Sie sich bewusst, dass Vermflex nicht verantwortlich ist für Inhalt und
            Datenschutz dieser Seiten. </p>
          <strong>VII. Newsletter</strong>
          <p>Für unseren Newsletter-Versand verwenden wir das sogenannte „Opt-In-Verfahren“. Dies bedeutet, dass wir
            Ihnen den Newsletter erst dann zusenden, wenn Sie uns zuvor ausdrücklich bestätigt haben, dass wir den
            Newsletter-Dienst aktivieren sollen. </p>
          <p>Beim Versand unseres Newsletters wird Mailchimp genutzt. Der Newsletter kann Weblinks enthalten. Mailchimp
            registriert, wer den Newsletter öffnet und wer was anklickt (Vorname, Name, E-Mail). Diese Informationen
            werden genutzt, um den Newsletterversand zu optimieren und den Inhalt des Newsletter noch besser auf Ihre
            Interessen anzupassen.</p>
          <p>Sollten Sie keine Newsletter mehr von uns erhalten wollen, können Sie Ihre Einwilligung zum Erhalt
            jederzeit widerrufen. Eine Mitteilung in Textform an die genannten Kontaktdaten reicht hierfür aus.
            Selbstverständlich finden Sie auch in jedem Newsletter einen Abmelde-Link.</p>
          <strong>VIII. Änderungen dieser Datenschutzerklärung</strong>
          <p>Vermflex kann diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils
            aktuelle, auf der Website publizierte Fassung.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Datenschutzerklärung",
  mounted() {
    document.title = "Datenschutzerklärung - scanner4you";
  },
};
</script>