<template>
    <div class="box mb-5">
        <h4 class="is-size-4 mb-6">Bestellung vom {{ formatDate(order.is_valid_from) }}</h4>
        <table class="table is-fullwidth order-overview">
            <thead>
                <tr>
                    <th>Produkt</th>
                    <th>Menge</th>
                    <th class="unit-price">Preis</th>
                    <th class="unit-price">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="position in order.positions" v-bind:key="position.id">
                    <td><div v-html="position.text"></div></td>
                    <td>{{ removeTrailingZeros(position.amount) }} {{ position.unit_name }}</td>
                    <td class="unit-price">{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(position.unit_price)}}</td>                    
                    <td class="total-price">{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(position.position_total)}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="3">Total</th>
                    <th class="unit-price">{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(order.total_gross)}}</th>
                </tr>
            </tfoot>            
        </table>
    </div>
</template>

<script>
export default {
    name: 'OrderSummary',
    props: {
        order: Object
    },
    methods: {
        formatDate(date) {
            let year = date.substring(2,4)
            let month = date.substring(5,7)
            let day = date.substring(8,10)
            return day+'.'+month+'.'+year
        },
        removeTrailingZeros(numStr) {
            return parseFloat(numStr).toString();
        }
    }
}
</script>