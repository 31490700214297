<template>
  <div v-if="loaded">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="page-title">FAQ</h1>
          </div>
        </div>
        <div class="col-lg-8 page-content">
          <div v-html="faq"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
export default {
  name: "FAQ",
  components: {
    Spinner
  },
  data() {
    return {
      loaded: false,
      faq: "",
    };
  },
  mounted() {
    document.title = "FAQ - scanner4you";
    this.getFAQ();
  },
  methods: {
    async getFAQ() {
      await axios
        .get(`/api/v1/content/faq`)
        .then((response) => {
          this.loaded = true;
          this.faq = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
}
</script>