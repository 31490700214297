<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title text-center">Vielen Dank</h1>
        <p class="text-center">Ihre Bestellung wird zeitnah bestätigt.</p>
        <div class="d-flex justify-content-center mb-3">
          <router-link to="/order">
            <button class="select-page">Meine Bestellungen</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  mounted() {
    document.title = "Success - scanner4you";
  },
};
</script>
<style>
</style>