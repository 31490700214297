<template>
  <nav>
    <div class="col-12">
      <ol class="breadcrumb">
        <li v-for="(crumb, ci) in crumbs" :key="ci" class="breadcrumb-item align-items-center">
          <a class="breadcrumb-item-link" :class="{ disabled: isLast(ci) }" @click="selected(crumb)">
            {{ crumb }}
          </a>
        </li>
      </ol>
    </div>
  </nav>
</template>

<script>
import router from '../router';

export default {
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      this.$emit('selected', crumb);
      if (crumb == 'home') {
        router.push('/');
      }
      if (crumb == 'Kaufen') {
        router.push('/kaufen');
      }
      if (crumb == 'Mieten') {
        router.push('/mieten');
      }
      if (crumb == 'Zubehör') {
        router.push('/kaufen/zubehoer');
      }
      if (crumb == 'Software & Services') {
        router.push('/kaufen/software-service');
      }
    },
  },
};
</script>
 
<style scoped>
.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/";
}
</style>