import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    cartToken: '',
    cartCount: 0,
    isAuthenticated: false,
    token: '',
  },
  mutations: {
    initializeStore(state) {
      // Shopping Cart
      if (localStorage.getItem('cartToken')) {
        state.cartToken = localStorage.getItem('cartToken')
      } else {
        function uuidv4() {
          return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
        }
        state.cartToken = uuidv4();
        localStorage.setItem('cartToken', state.cartToken)
      }
      // Auth Token
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    resetState(state) {
      // Reset Vuex state
      state.cartToken = '';
      state.cartCount = 0;
      state.isAuthenticated = false;
      state.token = '';

      // Clear local storage items
      localStorage.removeItem('token');
      localStorage.removeItem('cartToken');
    },
    addToCart(state, item) {
      const payload = {
        "cart": state.cartToken,
        "product": item.product.id,
        "quantity": item.quantity,
        "rentalstartdate": item.rentalstartdate,
        "rentalenddate": item.rentalenddate,
        "delivery": item.delivery?.id,
        "productservice": item.productservice?.id
      }
      axios
        .post(`/api/v1/cart/${item.product.category}/${item.product.id}/` , payload, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then((response) => {
          this.cart = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(`/api/v1/cart-count/${state.cartToken}/`)
        .then((response) => {
          state.cartCount = response.data.total_quantity;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
    switchCart(state, cartId) {
      state.cartToken = cartId
    },
    clearCart(state) {
      axios
        .delete(`/api/v1/cart/${state.cartToken}/`)
        .then((response) => {
          this.cart = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(`/api/v1/cart-count/${state.cartToken}/`)
        .then((response) => {
          state.cartCount = response.data.total_quantity;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateCartCount(state) {
      axios
        .get(`/api/v1/cart-count/${state.cartToken}/`)
        .then((response) => {
          state.cartCount = response.data.total_quantity;
          console.log(state.cartCount);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  actions: {
  },
  modules: {
  }
})
