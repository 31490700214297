<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-lg-6">
        <h1 class="page-title mb-0 pb-4 text-center">
          Anmeldebestätigung
        </h1>
        <p class="mb-4 text-center">Vielen Dank für Ihre Anmeldung zum Launch von scanner4you.
          Wir freuen uns sehr, Sie bei unserem Event begrüssen zu dürfen.
          Sie erhalten eine Bestätigung mit allen Informationen per E-Mail.
        </p>
        <div class="col-12 d-flex justify-content-center">
          <router-link to="/">
            <button class="btn-standard">Zurück zur Startseite</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Anmeldebestätigung",
};
</script>