<template>
  <div class="cart-item d-flex gap">
    <div class="col-sm-3">
      <router-link v-if="item.product.category !== 'addon'" v-bind:to="item.product.get_absolute_url" class="">
        <img :src="item.product.get_thumbnail"
          alt=""
          class="cart-item-image"
        />
      </router-link>
      <img v-else :src="item.product.get_thumbnail"
        alt=""
        class="cart-item-image"
      />
    </div>
    <div class="cart-item-information col">
      <div class="cart-item-description">
        <div>
          <router-link  v-if="item.product.category !== 'addon'" v-bind:to="item.product.get_absolute_url">
            <h4>
              <span>{{ item.display_name }}</span>
            </h4>
          </router-link>
          <h4 v-else>
              <span>{{ item.display_name }}</span>
            </h4>
          <div v-if="item.product.category === 'rental'" class="cart-item-category">Mieten</div>
          <div v-else-if="item.product.category === 'addon'" class="cart-item-category">Zusatzleistungen</div>
          <div v-else-if="item.product.category === 'education'" class="cart-item-category">Schulen</div>
          <div v-else class="cart-item-category">Kaufen</div>
          <p v-if="item.product.category === 'rental'">
              <span>Mietdauer: {{ new Date(item.rentalstartdate).toLocaleDateString("de-CH", {year: "numeric",  month: "2-digit",  day: "2-digit"}) }}</span>
              <span> - </span>
              <span>{{ new Date(item.rentalenddate).toLocaleDateString("de-CH", {year: "numeric",  month: "2-digit",  day: "2-digit"}) }}</span>
          </p>
          <p v-if="item.productservice">Datenverarbeitung: {{ item.productservice.name }}</p>
          <p v-if="item.delivery">Lieferung: {{ item.delivery.name }}</p>
        </div>
        <div @click="removeFromCart(item)" class="btn-remove" v-if="!item.product.mandatory">
          <button class="far fa-trash-alt"></button>
          Artikel entfernen
        </div>
      </div>
      <div>
        <div v-if="item.product.category === 'rental'" class="cart-item-price">
          {{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF', trailingZeroDisplay: 'stripIfInteger'}).format(item.total_shipping_price) }}
        </div>
        <div v-else class="cart-item-price">
          {{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF', trailingZeroDisplay: 'stripIfInteger'}).format(item.subtotal) }}
        </div>
        <div class="cart-item-actions" v-if="!(item.product.category === 'rental' || 'addon')">
          <div v-if="!item.product.mandatory" class="cart-item-quantity">
            <label class="quantity-label">Anzahl</label>
            <div class="quantity-selector">
              <button @click="addQuantity(item, -1)" class="btn-standard btn-quantity-minus">-</button>
              <div class="quantity-number">{{ item.quantity }}</div>
              <button @click="addQuantity(item, 1)" class="btn-standard btn-quantity-plus">+</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CartItem",
  props: {
    item: Object,
  },
  methods: {
    async addQuantity(item, quantityToAdd) {
      const payload = {
        "quantity": item.quantity + quantityToAdd
      }
      await axios
        .put(`/api/v1/cart/${item.product.category}/${item.id}/` , payload, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then((response) => {
          this.cart = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.updateCart();
    },
    updateCart() {
      localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
      this.$emit('updateCart')
    },
    async removeFromCart(item) {
      await axios
        .delete(`/api/v1/cart/${item.product.category}/${item.id}/`)
        .then((response) => {
          this.cart = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.updateCart();
    },      
  },
};
</script>
