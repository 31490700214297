<template>
  <div class="col-sm-12 col-md-6 col-lg-4">
    <router-link v-bind:to="product.get_absolute_url" class="buy-product">
      <div class="product-item">
        <div class="product-body d-flex flex-column justify-content-center mb-4">
          <h2 class="product-title text-center mb-2">{{ product.name }}</h2>
          <div class="product-thumbnail">
            <figure class="image mt-2 mb-2">
              <img v-if="product.get_thumbnail=='default'" src="../assets/images/accessory.png" />
              <img v-else v-bind:src="product.get_thumbnail" />
            </figure>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductBox_buy",
  props: {
    product: Object,
  },
};
</script>

<style>
</style>