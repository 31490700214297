<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="page-title text-center">Genauigkeit, die begeistert!</h1>
        <h2 class="display-p text-center">
          <div v-html="home_intro"></div>
        </h2>
        <img class="home-hero" src="../assets/images/leica.png" />
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-column align-items-center">
        <div class="accordion accordion-flush" id="accordionFlushExample" style="width:100%; border: none">
          <div class="accordion-item" style=" border: none">
            <h2 class="accordion-header" id="flush-headingOne"
              style="display: flex; justify-content: center; margin-bottom: 1rem !important;">
              <button class="accordion-button acordion-launch collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
                style="background: var(--product-background); border: none; padding: 14px 28px; border-radius: 0.2rem !important; display: inline-flex; align-items: center; width: 280px; justify-content: space-around;">
                Laserscanning
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-6 text-center">
                    <img class="home-image" src="../assets/images/laserscanning.png" />
                  </div>
                  <div class="col-md-6">
                    <div v-html="home_laserscanning" class="display-p"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item" style=" border: none">
            <h2 class="accordion-header" id="flush-headingThree"
              style="display: flex; justify-content: center; margin-bottom: 1rem !important;">
              <button class="accordion-button acordion-launch collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"
                style="background: var(--product-background); border: none; padding: 14px 28px; border-radius: 0.2rem !important; display: inline-flex; align-items: center; width: 280px; justify-content: space-around;">
                3D-Punktwolke
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-6 text-center">
                    <img class="home-image" src="../assets/images/punktwolke.png" />
                  </div>
                  <div class="col-md-6">
                    <div v-html="home_punktwolke" class="display-p"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo" style="display: flex; justify-content: center">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
                style="display: inline-flex; align-items: center; width: 280px; justify-content: space-around; background-color: var(--green); color: #fff; border: none; padding: 14px 28px; border-radius: 0.2rem !important;">
                Über uns
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <div class="row justify-content-center">
                  <div class="col-sm-12 col-lg-6">
                    <div v-html="home_ueberuns"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      home_intro: "",
      home_information: "",
      home_laserscanning: "",
      home_punktwolke: "",
      home_ueberuns: "",
    };
  },
  components: {
  },
  mounted() {
    document.title = "Ihr Ansprechpartner für Laserscanning - scanner4you";
    this.getHomeIntro();
    this.getHomeInformation();
    this.getHomeLaserscanning();
    this.getHomePunktwolke();
    this.getHomeUeberuns();
  },
  methods: {
    async getHomeIntro() {
      await axios
        .get(`/api/v1/content/home-intro`)
        .then((response) => {
          this.home_intro = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHomeInformation() {
      await axios
        .get(`/api/v1/content/home-information`)
        .then((response) => {
          this.home_information = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHomeLaserscanning() {
      await axios
        .get(`/api/v1/content/home-laserscanning`)
        .then((response) => {
          this.home_laserscanning = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHomePunktwolke() {
      await axios
        .get(`/api/v1/content/home-punktwolke`)
        .then((response) => {
          this.home_punktwolke = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHomeUeberuns() {
      await axios
        .get(`/api/v1/content/home-ueberuns`)
        .then((response) => {
          this.home_ueberuns = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>