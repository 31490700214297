<template>
  <div class="col-sm-12 col-md-4 col-lg-3">
    <div class="product-item mb-4">
      <router-link v-bind:to="product.get_absolute_url" class="buy-product">
        <div class="product-body softwareservices-body d-flex flex-column justify-content-center">
          <h2 class="product-title text-center">{{ product.name }}</h2>
          <div class="product-thumbnail">
            <figure class="image mt-2 mb-2">
              <img
                v-if="product.get_thumbnail == 'default'"
                src="../assets/images/software-services.png"
              />
              <img v-else v-bind:src="product.get_thumbnail" />
            </figure>
          </div>
        </div>
      </router-link>
    </div>
    <Toast
          v-bind:title="toasttitle"
          v-bind:body="toastbody"
        />
  </div>
</template>

<script>
import Toast from "@/components/Toast.vue";
export default {
  name: "ProductBox_buy",
  components: {
    Toast,
  },
  data() {
    return {
      toasttitle: "scanner4you",
      toastbody: "",
    }
  },
  props: {
    product: Object,
  },
  methods: {
    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }
      const item = {
        product: this.product,
        quantity: this.quantity,
      };
      this.$store.commit("addToCart", item);
      this.toastbody = "Das Zubehör wurde dem Warenkorb hinzugefügt."
      $('.toast').toast('show');
    },
  },
};
</script>

<style>
</style>