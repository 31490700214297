<template>
  <div class="schulen">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="page-title">Benutzer imitieren</h1>
            <p><b>Die folgende URL bitte in ein Inkognito Fenster kopieren (Chrome: Ctrl+Shift+N)</b></p>
            <div class="url-box p-3 mb-4">
              {{ currentUrl }}
            </div>
            <button @click="impersonateUser(queryParams.token, queryParams.cartId)" class="btn btn-danger">Ja, ich bin in einem Inkognito Fenster</button>
            <p class="mt-3">Nach der gewünschten Aktion bitte ausloggen.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';

export default {
  name: "Benutzer imitieren",
  data() {
    return {
      queryParams: {},
      route: {},
    }
  },
  mounted() {
    document.title = "Schulen - scanner4you";
    this.route = useRoute();
    this.getQueryParams();
  },
  methods: {
      getQueryParams() {
          this.queryParams = this.route.query;
      },
      impersonateUser(token, cartId) {
        // set stores
        this.$store.commit("setToken", token);
        this.$store.commit("switchCart", cartId);
        // Set cookie
        let expires = "";
        document.cookie = "csrftoken" + "=" + (token || "") + expires + "; path=/";
        // set local storage
        localStorage.setItem('token', token);
        // redirect
        window.location.href = "/";
      }
  },
  computed: {
    currentUrl() {
      return window.location.href;
    }
  }
};
</script>

<style scoped>
.url-box {
  background-color: lightgrey;
}
</style>