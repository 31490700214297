<template>
<div>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="page-title text-center">Mein Konto</h1>
        </div>
      </div>
      <div class="row logreg">
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="account-login">
            <h3>Passwort vergessen?</h3>
            <div v-if="success" class="">
              <p>Ihr Passwort wurde erfolgreich zurückgesetzt.</p>
            </div>
            <div v-else class="">
              <form @submit.prevent="submitForm">
                <div class="field">
                  <label class="form-label">Neues Passwort</label>
                  <div class="control">
                    <input type="password" class="form-input" v-model="password" />
                  </div>
                </div>
                <div class="field">
                  <label class="form-label">Neues Passwort wiederholen</label>
                  <div class="control">
                    <input type="password" class="form-input" v-model="password2" />
                  </div>
                </div>
                <div class="notification is-danger" v-if="errors.length">
                  <p v-for="error in errors" v-bind:key="error">
                    {{ error }}
                  </p>
                </div>
                <button class="login">zurücksetzen</button>
              </form>
            </div>            
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="account-register">
            <button class="login">
              <router-link to="/log-in">Login</router-link>
            </button>
            <button class="register">
              <router-link to="/sign-up">Als Firmenkunde registrieren</router-link>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-log-in">
    <div class="columns">
      <div class="column is-4 is-offset-4"></div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "LogIn",
  data() {
    return {
      password: "",
      password2: "",
      errors: [],
      success: 0,
    };
  },
  mounted() {
    document.title = "Passwort zurücksetzen - scanner4you";
  },
  methods: {
    async submitForm() {
      if (this.password !== this.password2) {
        this.errors.push("Die Passwörter stimmen nicht überein");
      }
      const formData = {
        uidb64: new URL(location.href).searchParams.get("uidb64"),
        token: new URL(location.href).searchParams.get("token"),
        password: this.password,
      };
      await axios
        .post("/api/v1/reset_user_password_confirm/", formData)
        .then((response) => {
          this.success = 1;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          } else {
            this.errors.push("Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00).");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>