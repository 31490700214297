<template>
    <div class="carousel">
        <div class="mainImages">
            <div v-if="video1" class="video">
                <iframe width="615" height="432" v-bind:src="'https://www.youtube.com/embed/' + product.videoId"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
            <div v-else-if="video2" class="video">
                <iframe width="615" height="432" v-bind:src="'https://www.youtube.com/embed/' + product.videoId2"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
            <div v-else-if="video3" class="video">
                <iframe width="615" height="432" v-bind:src="'https://www.youtube.com/embed/' + product.videoId3"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
            <img v-else class="mainImage" :src="mainSlide || product.get_image" />
        </div>
        <div v-if="multipleImages" class="thumbnails">
            <img v-if="product.get_image" @click="setMainImage(0)" id="0" index="0" class="thumbnail"
                :src="product.get_image" />
            <img v-if="product.get_image2" @click="setMainImage(1)" id="1" index="1" class="thumbnail"
                :src="product.get_image2" />
            <img v-if="product.get_image3" @click="setMainImage(2)" id="2" index="2" class="thumbnail"
                :src="product.get_image3" />
            <img v-if="product.get_image4" @click="setMainImage(3)" id="3" index="3" class="thumbnail"
                :src="product.get_image4" />
            <img v-if="product.get_image5" @click="setMainImage(4)" id="4" index="4" class="thumbnail"
                :src="product.get_image5" />
            <img v-if="product.get_image6" @click="setMainImage(5)" id="5" index="5" class="thumbnail"
                :src="product.get_image6" />
            <img v-if="youtubeThumbnailUrl" @click="setMainImage(6)" id="6" index="6" class="thumbnail youtube"
                :src="youtubeThumbnailUrl" />
            <img v-if="youtubeThumbnailUrl2" @click="setMainImage(7)" id="7" index="7" class="thumbnail youtube"
                :src="youtubeThumbnailUrl2" />
            <img v-if="youtubeThumbnailUrl3" @click="setMainImage(8)" id="8" index="8" class="thumbnail youtube"
                :src="youtubeThumbnailUrl3" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Carousel",
    data() {
        return {
            video1: false,
            video2: false,
            video3: false,
            mainSlide: '',
        }
    },
    props: {
        product: Object,
    },
    computed: {
        youtubeThumbnailUrl() {
            if (this.product.videoId) {
                let url
                url = 'https://img.youtube.com/vi/' + this.product.videoId + '/0.jpg'
                return url;
            }
        },
        youtubeThumbnailUrl2() {
            if (this.product.videoId2) {
                let url
                url = 'https://img.youtube.com/vi/' + this.product.videoId2 + '/0.jpg'
                return url;
            }
        },
        youtubeThumbnailUrl3() {
            if (this.product.videoId3) {
                let url
                url = 'https://img.youtube.com/vi/' + this.product.videoId3 + '/0.jpg'
                return url;
            }
        },
        multipleImages() {
            let items = []
            if (this.product.get_image) {
                items.push(this.product.get_image)
            }
            if (this.product.get_image2) {
                items.push(this.product.get_image2)
            }
            if (this.product.get_image3) {
                items.push(this.product.get_image3)
            }
            if (this.product.get_image4) {
                items.push(this.product.get_image4)
            }
            if (this.product.get_image5) {
                items.push(this.product.get_image5)
            }
            if (this.product.get_image6) {
                items.push(this.product.get_image6)
            }
            if (this.youtubeThumbnailUrl) {
                items.push(this.youtubeThumbnailUrl)
            }
            if (this.youtubeThumbnailUrl2) {
                items.push(this.youtubeThumbnailUrl2)
            }
            if (this.youtubeThumbnailUrl3) {
                items.push(this.youtubeThumbnailUrl3)
            }
            if (items.length > 1) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        setMainImage(index) {
            if (index === 1) {
                this.mainSlide = this.product.get_image2;
                this.video1 = false;
                this.video2 = false;
                this.video3 = false;
            } else if (index === 2) {
                this.mainSlide = this.product.get_image3;
                this.video1 = false;
                this.video2 = false;
                this.video3 = false;
            } else if (index === 3) {
                this.mainSlide = this.product.get_image4;
                this.video1 = false;
                this.video2 = false;
                this.video3 = false;
            } else if (index === 4) {
                this.mainSlide = this.product.get_image5;
                this.video1 = false;
                this.video2 = false;
                this.video3 = false;
            } else if (index === 5) {
                this.mainSlide = this.product.get_image6;
                this.video1 = false;
                this.video2 = false;
                this.video3 = false;
            } else if (index === 6) {
                this.mainSlide = false;
                this.video1 = true;
                this.video2 = false;
                this.video3 = false;
            } else if (index === 7) {
                this.mainSlide = false;
                this.video1 = false;
                this.video2 = true;
                this.video3 = false;
            } else if (index === 8) {
                this.mainSlide = false;
                this.video1 = false;
                this.video2 = false;
                this.video3 = true;
            } else {
                this.mainSlide = this.product.get_image;
                this.video1 = false;
                this.video2 = false;
                this.video3 = false;
            }

            let i = 0;

            while (i < 9) {
                try { document.getElementById(i).classList.remove('active'); } catch { }
                i++;
                try { document.getElementById(index).classList.add('active'); } catch { }
            }
        },
    },
}
</script>

<style>
.active {
    border: 3px solid #555;
}

.thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.crousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>