<template>
  <div v-if="loaded" class="container pt-6">
    <div class="row justify-content-center">
      <div class="col-12">
        <h1 class="page-title text-center">Laserscanner mieten </h1>
      </div>
      <div class="col-6 ">
        <p class="page-lead text-center mb-5">Die Anwendung der neuen Laserscanner ist einfach: <br>Genaue Messdaten
          effizient in
          Form einer 3D-Punktwolke aufnehmen!</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="page-subtitle">Ihr Anwendungsbereich</h2>
        <div v-if="purposes"
          class="d-flex justify-content-sm-start justify-content-md-center model-chooser mb-5 flex-wrap">
          <button v-bind:class="{ 'select-product': true, 'active': activePurposeId === '' }"
            @click="getCategory()">Alle</button>
          <button v-for="purpose in purposes" v-bind:key="purpose.id"
            v-bind:class="{ 'select-product': true, 'active': activePurposeId === purpose.id }"
            @click="getCategory(purpose.id)">
            {{ purpose.name }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h3 class="product-recommendation text-center">Unser Vorschlag</h3>
      </div>
      <ProductBox_rent v-for="product in products" v-bind:key="product.id" v-bind:product="product" />
    </div>
    <Toast v-bind:title="toasttitle" v-bind:body="toastbody" />
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>

<script>
import axios from "axios";
import Toast from "@/components/Toast.vue";
import Spinner from "@/components/Spinner.vue";
import ProductBox_rent from "@/components/ProductBox_rent";
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: "Category",
  components: {
    ProductBox_rent,
    Breadcrumb,
    Spinner
  },
  data() {
    return {
      products: [],
      purposes: [],
      activePurposeId: '',
      toasttitle: "scanner4you",
      toastbody: "",
      crumbs: ['Mieten'],
      loaded: false
    };
  },
  mounted() {
    document.title = "Mieten - scanner4you";
    this.getPurposes();
    this.getCategory();
  },
  watch: {
    $route(to, from) {
      if (to.name === "Category") {
        this.getCategory();
      }
    },
  },
  methods: {
    async getCategory(purpose_id = '') {
      this.activePurposeId = purpose_id;
      const url = purpose_id ? `/api/v1/products/rental/?purpose=${purpose_id}` : '/api/v1/products/rental/';
      axios
        .get(url)
        .then((response) => {
          this.products = response.data;
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
          this.toastbody = "Etwas ist schiefgelaufen. Bitte versuchen Sie es nochmals.";
          $('.toast').toast('show');
        });
    },
    async getPurposes() {
      axios
        .get(`/api/v1/products/rental-purposes/`)
        .then((response) => {
          this.purposes = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.toastbody = "Etwas ist schiefgelaufen. Bitte versuchen Sie es nochmals."
          $('.toast').toast('show');
        });
    },
  },
};
</script>
