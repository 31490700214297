<template>
  <button type="button" class="btn-tooltip" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right"
    data-bs-custom-class="custom-tooltip" data-bs-title="bei Verzögerung werden Sie benachrichtigt">
    <i class="bi bi-info-circle"></i>
  </button>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    body: String,
  },
  mounted() {
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
      .forEach(tooltip => {
        new bootstrap.Tooltip(tooltip)
      })
  },
};
</script>
