<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title text-center">Aktivierung Kundenkonto</h1>
        <div class="text-center">
          <p>
            Aus Sicherheitsgründen verifizieren wir Ihre E-Mailadresse.
            Dazu haben wir Ihnen eine E-Mail mit einem Bestätigungslink an Ihre
            Mailadresse gesendet.
          </p>
          <p>
            Sobald Sie Ihre E-Mail-Adresse über den Bestätigungslink verifizieren,
            erhalten Sie Zugang zu Ihrem Kundenkonto.
          </p>
          <p>
            Haben Sie keine E-Mail erhalten? Prüfen Sie bitte Ihren Spam-Ordner
            oder nehmen Sie mit uns
            <a href="tel:+41 71 311 44 00" class="link">Kontakt</a> auf.
          </p>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <router-link to="/">
          <button class="btn-standard">Zurück zur Startseite</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivationPending",
};
</script>