<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-lg-6">
        <h1 class="page-title mb-0 pb-4 text-center">
          Neu bei scanner4you?
        </h1>
        <p class="mb-4">Jetzt einfach registrieren, um Bestellungen zu tätigen und Kundenvorteile zu nutzen.</p>
        <form @submit.prevent="submitForm">
          <h3>E-Mail und Passwort</h3>
          <div class="col-sm-12 col-md-12 field">
            <label class="form-label">E-Mail *</label>
            <div class="control">
              <input type="text" class="form-input" v-model="email" autocomplete="email" />
            </div>
          </div>
          <div class="d-flex gap">
            <div class="col field">
              <label class="form-label">Passwort *</label>
              <div class="control">
                <input type="password" class="form-input" v-model="password" autocomplete="new-password" />
              </div>
            </div>
            <div class="col field">
              <label class="form-label">Passwort wiederholen *</label>
              <div class="control">
                <input type="password" class="form-input" v-model="password2" autocomplete="new-password" />
              </div>
            </div>
          </div>
          <h3>Name und Anschrift</h3>
          <div class="d-flex gap">
            <div class="col field">
              <label class="form-label">Vorname *</label>
              <div class="control">
                <input type="text" class="form-input" v-model="first_name" autocomplete="given-name" />
              </div>
            </div>
            <div class="col field">
              <label class="form-label">Name *</label>
              <div class="control">
                <input type="text" class="form-input" v-model="last_name" autocomplete="family-name" />
              </div>
            </div>
          </div>
          <div class="d-flex gap">
            <div class="col field">
              <label class="form-label">Firma *</label>
              <div class="field">
                <input type="text" class="form-input" v-model="company" autocomplete="organization" />
              </div>
            </div>
            <div class="col field">
              <label class="form-label">Branche *</label>
              <select class="form-select" v-model="sector" aria-label="Default select example">
                <option selected disabled>Auswählen</option>
                <option value="Architekturbau">Architekturbau</option>
                <option value="Ingenieursbau">Ingenieursbau</option>
                <option value="Schlosser- und Metallbau">Schlosser und Metallbau</option>
                <option value="Elektro- und Anlagebau">Elektro- und Anlagebau</option>
                <option value="Sanitärgewerbe">Sanitärgewerbe</option>
                <option value="Maurergewerbe">Maurergewerbe</option>
                <option value="Malergewerbe">Malergewerbe</option>
                <option value="Raumplanung">Raumplanung</option>
                <option value="Andere">Andere</option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="form-label">Strasse *</label>
            <div class="control">
              <input type="text" class="form-input" v-model="address" autocomplete="street-address" />
            </div>
          </div>
          <div class="d-flex gap">
            <div class="field plz">
              <label class="form-label">PLZ *</label>
              <div class="control">
                <input type="text" class="form-input" v-model="postalcode" autocomplete="postal-code" />
              </div>
            </div>
            <div class="field ort">
              <label class="form-label">Ort *</label>
              <div class="control">
                <input type="text" class="form-input" v-model="city" autocomplete="address-level2" />
              </div>
            </div>
          </div>
          <div class="field">
            <label class="form-label">Telefon*</label>
            <div class="control">
              <input type="text" class="form-input" v-model="phone" autocomplete="tel" />
            </div>
          </div>
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
          <div>
            <p>
              Mit der Registrierung erkläre ich mich mit dem Erhalt von
              individualisierten
              Informationen, Angeboten und Gutscheinen einverstanden.
            </p>
          </div>
          <div>
            <p>*Pflichtfelder</p>
          </div>
          <button class="login">Registrieren</button>
        </form>
      </div>
    </div>
    <Toast v-bind:title="toasttitle" v-bind:body="toastbody" />
  </div>
</template>

<script>
import axios from "axios";
import Toast from "@/components/Toast.vue";
export default {
  name: "SignUp",
  data() {
    return {
      company: "",
      sector: "Auswählen",
      first_name: "",
      last_name: "",
      address: "",
      postalcode: "",
      city: "",
      phone: "",
      email: "",
      password: "",
      password2: "",
      errors: [],
      toasttitle: "scanner4you",
      toastbody: "",
    };
  },
  mounted() {
    document.title = "Als Kunde registrieren - scanner4you";
  },
  methods: {
    submitForm() {
      this.errors = [];
      if (this.company === "") {
        this.errors.push("Bitte Firma eingeben");
      }
      if (this.sector === "Auswählen") {
        this.errors.push("Bitte Branche auswählen");
      }
      if (this.first_name === "") {
        this.errors.push("Bitte Vornamen eingeben");
      }
      if (this.last_name === "") {
        this.errors.push("Bitte Namen eingeben");
      }
      if (this.address === "") {
        this.errors.push("Bitte Adresse eingeben");
      }
      if (this.postalcode === "") {
        this.errors.push("Bitte Postleitzahl eingeben");
      }
      if (this.city === "") {
        this.errors.push("Bitte Ort eingeben");
      }
      if (this.phone === "") {
        this.errors.push("Bitte Telefonnummer eingeben");
      }
      if (this.email === "") {
        this.errors.push("Bitte E-Mail Adresse eingeben");
      }
      if (this.password === "") {
        this.errors.push("Das Passwort ist zu kurz");
      }
      if (this.password !== this.password2) {
        this.errors.push("Die Passwörter stimmen nicht überein");
      }
      if (!this.errors.length) {
        const formData = {
          company: this.company,
          sector: this.sector,
          first_name: this.first_name,
          last_name: this.last_name,
          address: this.address,
          postalcode: this.postalcode,
          city: this.city,
          phone: this.phone,
          email: this.email,
          password: this.password,
        };
        axios
          .post("/api/v1/signup/", formData)
          .then((response) => {
            this.toastbody = "Das Konto wurde erfolgreich angelegt."
            $('.toast').toast('show');
            this.$router.push("/activation");
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors.push("Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00).");
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>