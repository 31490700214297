<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="page-title text-center">Kasse</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9">
          <div class="order-overview-address mb-4">
            <h2 class="subtitle">Rechnungsadresse</h2>
            <div class="columns multiline">
              <div class="field">
                  <label class="form-label">Referenznummer</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="referencenum" autocomplete="" />
                  </div>
              </div>
              <div class="column is-6">
                <div class="d-flex gap">
                  <div class="col field">
                    <label class="form-label">Vorname*</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="first_name" autocomplete="given-name" disabled />
                    </div>
                  </div>
                  <div class="col field">
                    <label class="form-label">Name *</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="last_name" autocomplete="family-name" disabled/>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label class="form-label">Firma</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="company" autocomplete="organization" disabled />
                  </div>
                </div>
                <div class="field">
                  <label class="form-label">Adresse *</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="address" autocomplete="street-address" disabled />
                  </div>
                </div>
                <div class="d-flex gap">
                  <div class="plz field">
                    <label class="form-label">PLZ*</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="postalcode" autocomplete="postal-code" disabled />
                    </div>
                  </div>
                  <div class="ort field">
                    <label class="form-label">Ort*</label>
                    <div class="control">
                      <input type="email" class="form-input" v-model="city" autocomplete="address-level2" disabled />
                    </div>
                  </div>
                </div>
                <div class="d-flex gap">
                  <div class="col field">
                    <label class="form-label">Telefon*</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="phone" autocomplete="tel"
                        placeholder="071 234 56 78" disabled />
                    </div>
                  </div>
                  <div class="col field">
                    <label class="form-label">E-Mail*</label>
                    <div class="control">
                      <input type="email" class="form-input" v-model="email" autocomplete="email" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-overview-address mb-5">
            <input type="checkbox" id="Lieferadresse" name="scales" v-model="noshippingaddress">
            <label for="scales">Rechnungsadresse entspricht Lieferadresse</label>
          </div>
          <div class="order-overview-address mb-4" v-if="noshippingaddress == false">
            <h2 class="subtitle">Lieferadresse</h2>
            <div class="columns multiline">
              <div class="column is-6">
                <div class="d-flex gap">
                  <div class="col field">
                    <label class="form-label">Vorname*</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="first_name2" autocomplete="given-name"/>
                    </div>
                  </div>
                  <div class="col field">
                    <label class="form-label">Name *</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="last_name2" autocomplete="family-name"/>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label class="form-label">Firma *</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="company2" autocomplete="organization"/>
                  </div>
                </div>
                <div class="field">
                  <label class="form-label">Adresse *</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="address2" autocomplete="street-address"/>
                  </div>
                </div>
                <div class="d-flex gap">
                  <div class="plz field">
                    <label class="form-label">PLZ*</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="postalcode2" autocomplete="postal-code"/>
                    </div>
                  </div>
                  <div class="ort field">
                    <label class="form-label">Ort*</label>
                    <div class="control">
                      <input type="email" class="form-input" v-model="city2" autocomplete="address-level2"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex gap">
                  <div class="col field">
                    <label class="form-label">Telefon*</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="phone2" autocomplete="tel" placeholder="071 234 56 78"/>
                    </div>
                  </div>
                  <div class="col field">
                    <label class="form-label">E-Mail*</label>
                    <div class="control">
                      <input type="email" class="form-input" v-model="email2" autocomplete="email"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <h3>Bestellübersicht</h3>
            <table class="table is-fullwidth order-overview">
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Menge</th>
                  <th>Preis</th>
                  <th>CHF</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in cart.items" v-bind:key="item.product.id">
                  <td>
                    {{ item.display_name }}
                    <br>
                    <em v-if="item.product.category === 'rental'"> Mietzeitraum: {{ new Date(item.rentalstartdate).toLocaleDateString("de-CH", {year: "numeric",  month: "2-digit",  day: "2-digit"}) }} - {{ new Date(item.rentalenddate).toLocaleDateString("de-CH", {year: "numeric",  month: "2-digit",  day: "2-digit"}) }}</em>
                    <div v-if="item.productservice"><br>{{ item.productservice.name }}<br><em>{{ item.productservice.description }}</em></div>
                    <div v-if="item.delivery"><br>{{ item.delivery.name }}<br><em>{{ item.delivery.description }}</em></div>
                  </td>
                  <td>
                    {{ item.display_quantity }}
                    <div v-if="item.productservice"><br><br>1</div>
                    <div v-if="item.delivery"><br><br>1</div>
                  </td>
                  <td>
                    {{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.product.price) }}
                    <div v-if="item.productservice"><br><br>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.productservice.price) }}</div>
                    <div v-if="item.delivery"><br><br>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.delivery.price) }}</div>
                  </td>
                  <td>
                    {{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.subtotal) }}
                    <div v-if="item.productservice"><br><br>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.productservice.price) }}</div>
                    <div v-if="item.delivery"><br><br>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.delivery.price) }}</div>
                  </td>
                </tr>
                <tr v-if="cart.shipping_cost" class="shipping-info">
                  <td>Versandkosten</td>
                  <td>1</td>
                  <td>
                    {{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.shipping_cost) }}
                  </td>
                  <td>
                    {{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.shipping_cost) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="3">Total</th>
                  <th>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.total_shipping_price) }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <h3>Zusammenfassung der Bestellung</h3>
            <div class="cart-items-qty">{{ cart.total_quantity }} Artikel</div>
            <hr class="cart-line" />
            <div class="cart-detail">
              <span>Total exkl. MwSt.</span>
              <span>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.total_shipping_price) }}</span>
            </div>
            <div class="cart-detail">
              <span>8.1% MwSt.</span>
              <span>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.total_shipping_vat) }}</span>
            </div>
            <hr class="cart-line" />
            <div class="cart-detail bold">
              <span>Total inkl. MwSt.</span>
              <span>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.total_shipping_price+cart.total_shipping_vat) }}</span>
            </div>
          </div>
          <div v-if="cart.total_quantity">
            <button v-if="loaded" class="btn-standard btn-cart" @click="submitForm">
              Kostenpflichtig bestellen
            </button>
            <button v-else class="btn-standard btn-cart disabled" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Kostenpflichtig bestellen
            </button>
            <p class="order-terms">Mit der Bestellung akzeptieren Sie unsere <a href="/agb" target="_blank">AGB</a> und <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.</p>
          </div>
          <div class="notification is-danger mt-4" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Checkout",
  data() {
    return {
      cart: {
        items: [],
      },
      referencenum: "",
      company: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      postalcode: "",
      city: "",
      company: "",
      noshippingaddress: true,
      first_name2: "",
      last_name2: "",
      email2: "",
      phone2: "",
      address2: "",
      postalcode2: "",
      city2: "",
      errors: [],
      loaded: true,
    };
  },
  mounted() {
    document.title = "Kasse - scanner4you";
    this.getCart();
    this.addShippingCost();
    this.getContact();
  },
  methods: {
    async getCart() {
      const cartToken = this.$store.state.cartToken

      await axios
        .get(`/api/v1/cart/${cartToken}`)
        .then((response) => {
          this.cart = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addShippingCost() {
      let accessoryInCart = false;
      let purchaseInCart = false;
      this.cart.items.forEach((item) => {
        if (item.product.category == 'accessory') {
          accessoryInCart = true;
        }
        if (item.product.category == 'purchase' || item.product.category == 'package') {
          purchaseInCart = true;
        }
      });
      if (purchaseInCart === false && accessoryInCart === true) {
        let product_item = {
          product: {
            id: 'VK1',
            category: "shipping",
            name: "Versandkosten",
            price: "10.00"
          },
          quantity: 1
        }
        this.cart.items.push(product_item);
      };
    },
    async getContact() {
      await axios
        .get("/api/v1/profile/")
        .then((response) => {
          this.company = response.data["company"];
          this.first_name = response.data["first_name"];
          this.last_name = response.data["last_name"];
          this.email = response.data["email"];
          this.phone = response.data["phone"];
          this.address = response.data["address"];
          this.postalcode = response.data["postalcode"];
          this.city = response.data["city"];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async placeOrder() {
      let data = {
        // billing address
        referencenum: this.referencenum,
        company: this.company,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        address: this.address,
        postalcode: this.postalcode,
        city: this.city,
        phone: this.phone,
        // shipping address
        company2: this.company2,
        first_name2: this.first_name2,
        last_name2: this.last_name2,
        email2: this.email2,
        address2: this.address2,
        postalcode2: this.postalcode2,
        city2: this.city2,
        phone2: this.phone2,
      };
      // overwrite shipping address with billing address if no shipping address
      if (this.noshippingaddress == true) {
        data.company2 = this.company;
        data.first_name2 = this.first_name;
        data.last_name2 = this.last_name;
        data.email2 = this.email;
        data.address2 = this.address;
        data.postalcode2 = this.postalcode;
        data.city2 = this.city;
        data.phone2 = this.phone;
      };

      const cartToken = this.$store.state.cartToken

      await axios
        .post(`/api/v1/checkout/${cartToken}/`, data)
        .then((response) => {
          this.$router.push("/cart/success");
        })
        .catch((error) => {
          this.loaded = true;
          this.errors.push("Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00).");
          console.log(error);
        });
    },
    submitForm() {
      this.loaded = false
      this.errors = [];
      if (this.first_name === "") {
        this.errors.push("Bitte Vornamen eingeben");
      }
      if (this.last_name === "") {
        this.errors.push("Bitte Namen eingeben");
      }
      if (this.address === "") {
        this.errors.push("Bitte Adresse eingeben");
      }
      if (this.postalcode === "") {
        this.errors.push("Bitte Postleitzahl eingeben");
      }
      if (this.city === "") {
        this.errors.push("Bitte Ort eingeben");
      }
      if (this.phone === "") {
        this.errors.push("Bitte Telefonnummer eingeben");
      }
      if (this.email === "") {
        this.errors.push("Bitte E-Mail Adresse eingeben");
      }
      if (this.noshippingaddress == false) {
        if (this.first_name2 === "") {
          this.errors.push("Bitte Vornamen eingeben");
        }
        if (this.last_name2 === "") {
          this.errors.push("Bitte Namen eingeben");
        }
        if (this.address2 === "") {
          this.errors.push("Bitte Adresse eingeben");
        }
        if (this.postalcode2 === "") {
          this.errors.push("Bitte Postleitzahl eingeben");
        }
        if (this.city2 === "") {
          this.errors.push("Bitte Ort eingeben");
        }
        if (this.phone2 === "") {
          this.errors.push("Bitte Telefonnummer eingeben");
        }
        if (this.email2 === "") {
          this.errors.push("Bitte E-Mail Adresse eingeben");
        }
      }
      if (this.errors.length) {
        this.loaded = true;
      } else {
        this.placeOrder();
      }
    },
  }
};
</script>