<template>
  <div class="schulen">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="page-title">
              Ihr kompetenter Schulungspartner
            </h1>
            <p>
              Wir stehen für Individualität und haben viel Erfahrung im Bereich Laserscanning. 
              <br />
              Gerne unterstützen wir Sie bei Ihren Projekten und freuen uns auf Ihre Kontaktaufnahme.
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <a href="tel:+41 71 311 44 00" class="contact-us-icon">
              <i class="fs-1 bi bi-telephone"></i>
            </a>
            <a href="mailto:info@scanner4you.ch" class="contact-us-icon">
              <i class="fs-1 bi bi-envelope-at"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Schulen",
  mounted() {
    document.title = "Schulen - scanner4you";
  },
};
</script>