<template>
    <div class="schulen">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="text-center">
                        <h1 class="page-title">Allgemeine Geschäftsbedingungen (AGB)</h1>
                    </div>
                </div>
                <div class="col-lg-8 page-content">
                    <h2>I. Allgemeine Bestimmungen</h2>
                    <h3>1. Geltungsbereich</h3>
                    <p>
                        1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend AGB genannt) regeln Abschluss, Inhalt und Abwicklung von Verträgen zwischen der Scanner4you by Vermflex GmbH (nachfolgend „Vermflex“) und dem Kunden.
                    </p>
                    <p>
                        1.2 Mit der Einreichung der Bestellung gelten diese AGB vom Kunden als akzeptiert.
                    </p>
                    <p>
                        1.3 Änderungen oder Ergänzungen bedürfen der schriftlichen Bestätigung der Vermflex.
                    </p>
                    <p>
                        1.4 Abweichende, entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen des Kunden werden, selbst wenn sie der Vermflex bekannt sind, nicht Vertragsbestandteil, es sei denn, die Vermflex stimme ihrer
                        Geltung ausdrücklich zu. Es wird unwiderlegbar angenommen, dass der Kunde von den vorliegenden AGB spätestens im Zeitpunkt des Vertragsabschlusses vollumfänglich Kenntnis erhalten und sie vorbehaltlos und in vollem
                        Umfang akzeptiert hat.
                    </p>
                    <p>
                        1.5 Die Adresse und Kontaktdetails der Vermflex sind:<br />
                        Scanner4you<br />
                        Vermflex GmbH<br />
                        Bionstrasse 3<br />
                        9015 St. Gallen<br />
                        E-Mail-Korrespondenz: info@scanner4you.ch
                    </p>
                    <h3>2. Angebot, Offerte und Vertragsschluss</h3>
                    <p>2.1 Jede Bestellung setzt eine vorherige Anmeldung bzw. Registrierung als Kunde voraus. Mehrfachanmeldungen unter verschiedenen Namen oder Adressen sind unzulässig.</p>
                    <p>2.2 Die Angebote der Vermflex sind freibleibend und unverbindlich.</p>
                    <p>2.3 Indem der Kunde per Internet (durch Anklicken des Buttons „Kostenpflichtig bestellen“), E-Mail, Telefon oder über sonstige Kommunikationswege eine Bestellung absendet, gibt er einen verbindlichen Antrag auf
                        Vertragsabschluss ab. Der Kunde ist an seine Bestellung 14 Tage ab Empfang durch die Vermflex gebunden.
                    </p>
                    <p>2.4 Der Vertrag zwischen dem Kunden und der Vermflex kommt mit der vorbehaltlosen Annahme durch die Vermflex zustande. Die Annahme erfolgt, indem die Annahme ausdrücklich erklärt bzw. das bestellte Produkt an den
                        Kunden versendet wird.
                    </p>
                    <p>2.5 Die Vermflex kann Bestellungen ohne Angabe von Gründen ablehnen.</p>
                    <p>2.6 Bestellt der Kunde Ware, die an einen Dritten ausgeliefert werden soll, so gilt der Besteller als Vertragspartner, falls nichts anderes ausdrücklich vereinbart ist.</p>
                    <h3>3. Preise</h3>
                    <p>3.1 Die angebotenen Preise sind bindend. Die Preise verstehen sich in Schweizer Franken und exklusive Mehrwertsteuer, sofern nicht etwas anderes vereinbart wurde.</p>
                    <p>3.2 Sämtliche Nebenkosten wie, ohne darauf beschränkt zu sein, Versand- bzw. Transportkosten, gehen zu Lasten des Kunden, sofern nicht etwas anderes vereinbart wurde.</p>
                    <h3>4. Fälligkeit / Zahlungsverzug</h3>
                    <p>4.1 Die Zahlung des Kunden ist am auf der Rechnung ausgewiesenen Termin fällig (Fälligkeitstermin). Sie hat in Auftragswährung rein netto, d.h. ohne jeglichen Abzug und unter Ausschluss der Verrechnung zu erfolgen.
                        Nach unbenütztem Verstreichen des Fälligkeitstermins befindet sich der Kunde in Zahlungsverzug und schuldet Verzugszins von 5% p.a. Die Vermflex muss Lieferungen (auch aus anderen Verträgen) nicht erfüllen, solange
                        sich der Kunde in Zahlungsverzug befindet, in irgendein Pfändungs- oder Konkursverfahren verwickelt ist oder eine wesentliche Verschlechterung seiner Vermögenslage eingetreten ist.
                    </p>
                    <h3>5. Lieferung</h3>
                    <p>5.1 Liefertermine sind nur verbindlich, wenn sie von der Vermflex schriftlich bestätigt werden.</p>
                    <p>5.2 Gerät die Vermflex in Verzug, so ist ihr zunächst eine angemessene Frist zur Erbringung ihrer Lieferung zu gewähren. Nach fruchtlosem Ablauf der Frist kann der Kunde vom Vertrag zurücktreten. Ansprüche auf Ersatz
                        des Verzugsschadens sind ausgeschlossen.
                    </p>
                    <p>5.3 Ist die Nichteinhaltung der Frist auf höhere Gewalt oder andere von der Vermflex nicht zu vertretende Ereignisse zurückzuführen, verschiebt sich der vereinbarte Liefertermin entsprechend. Lässt sich das
                        Lieferhindernis nicht innerhalb einer angemessenen Frist von maximal einem Monat beseitigen, sind sowohl der Kunde als auch die Vermflex berechtigt, vom Vertrag zurückzutreten. Bereits erbrachte Zahlungen wird die
                        Vermflex dem Kunden unverzüglich zurückerstatten.
                    </p>
                    <p>5.4 Die Vermflex ist berechtigt, Teillieferungen zu erbringen und entsprechend Teilzahlungen zu verlangen. Sofern Teillieferungen durchgeführt werden, übernimmt die Vermflex die zusätzlichen Versandkosten.</p>
                    <h3>6. Haftung</h3>
                    <p>
                        6.1 Alle Fälle von Vertragsverletzungen und deren Rechtsfolgen sowie alle Ansprüche des Kunden, gleichgültig aus welchem Rechtsgrund sie gestellt werden, sind in diesen AGB abschliessend geregelt. In keinem Fall
                        bestehen Ansprüche des Kunden auf Ersatz von Schäden, die nicht am Produkt selbst entstanden sind, wie namentlich Produktionsausfall, Verlust von Aufträgen, entgangener Gewinn sowie von anderen mittelbaren oder
                        unmittelbaren Schäden oder Folgeschäden. Ein Haftungsanspruch, der den Wert der Bestellung übersteigt, wird ausgeschlossen.
                    </p>
                    <p>6.2 Der Haftungsausschluss gemäss vorstehender Ziffer gilt nicht für rechtswidrige Absicht (Vorsatz) oder für grobe Fahrlässigkeit.</p>
                    <p>6.3 Die bestellten Produkte dürfen nur für den vereinbarten und bestimmungsgemässen Gebrauch verwendet werden. Für andere Gebrauchsanwendungen muss vorgängig eine schriftliche Zustimmung eingeholt werden.</p>
                    <h3>7. Datenschutz</h3>
                    <p>
                        7.1 Die Vermflex verpflichtet sich, die personenbezogenen Daten der Kunden nach den anwendbaren datenschutzrechtlichen Bestimmungen der Schweiz zu behandeln. Für Einzelheiten sei auf die separate
                        <a href="/datenschutz">Datenschutzerklärung</a> verwiesen.
                    </p>
                    <h3>8. Anwendbares Recht / Gerichtsstand</h3>
                    <p>
                        8.1 Der Vertrag untersteht dem materiellen schweizerischen Recht. Die Anwendbarkeit des Übereinkommens der Vereinigten Nationen über den internationalen Warenverkehr vom 11. April 1980 (Wiener Kaufrecht) wird
                        ausdrücklich ausgeschlossen.
                    </p>
                    <p>8.2 Gerichtsstand ist der Sitz der Vermflex. Die Vermflex ist indessen auch berechtigt, den Kunden beim zuständigen Gericht seines Domizils oder jedem anderen zuständigen Gericht zu belangen.</p>
                    <h3>9. Salvatorische Klausel</h3>
                    <p>
                        9.1 Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam sein oder werden, so wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Die ganz oder teilweise unwirksame Regelung soll
                        durch eine solche ersetzt werden, deren wirtschaftlicher Erfolg dem der unwirksamen möglichst nahekommt.
                    </p>
                    <h2>II. Ergänzende Bestimmungen beim Kauf von Produkten</h2>
                    <h3>10. Allgemeines</h3>
                    <p>10.1 Die Bestimmungen in diesem Kapitel kommen ergänzend zur Anwendung, wenn der Kunde Produkte der Vermflex erwirbt.</p>
                    <h3>11. Übergang von Nutzen und Gefahr</h3>
                    <p>
                        11.1 Nutzen und Gefahr gehen mit Abgang der Lieferung auf den Kunden über. Falls sich der Versand auf Begehren des Kunden oder aus sonstigen Gründen, die die Vermflex nicht zu vertreten hat, verzögert, geht die
                        Gefahr im ursprünglich für die Ablieferung ab Werk vorgesehenen Zeitpunkt auf den Kunden über. Eine im Einzelfall vereinbarte Übernahme der Transportkosten durch die Vermflex hat keinen Einfluss auf den
                        Gefahrübergang.
                    </p>
                    <h3>12. Gewährleistung</h3>
                    <p>
                        12.1 Sind die gelieferten Waren zum Zeitpunkt des Gefahrübergangs mangelhaft, z.B. aufgrund eines Herstellerfehlers, oder liegt eine Falschlieferung oder eine unvollständig bleibende Lieferung vor, liefert die
                        Vermflex nach eigener Wahl Ersatz oder macht den Kauf rückgängig und stattet dem Kunden den Kaufpreis zurück.
                    </p>
                    <p>12.2 Der Kunde prüft die Beschaffenheit der Ware und teilt der Vermflex Mängel/Falschlieferungen innerhalb von 10 Tagen nach Empfang schriftlich mit, andernfalls gilt die Ware als genehmigt.</p>
                    <p>
                        12.3 Als Beschaffenheit der Ware gilt nur die Produktebeschreibung bzw. die technische Produktespezifikation des Herstellers als vereinbart. Öffentliche Äusserungen, Anpreisungen oder Werbung des Herstellers stellen
                        keine vertragsgemässe Beschaffenheitsangabe der Ware dar.
                    </p>
                    <p>12.4 Der Gewährleistungsanspruch des Kunden erlischt, wenn er den Mangel oder die Falschlieferung nicht innerhalb von 10 Tagen nach Erhalt der Ware schriftlich der Vermflex mitteilt.</p>
                    <h3>13. Eigentumsvorbehalt</h3>
                    <p>13.1 Die gelieferte Ware bleibt bis zur vollständigen Begleichung sämtlicher Forderungen aus der Geschäftsbeziehung Eigentum der Vermflex.</p>
                    <h2>III. Ergänzende Bestimmungen bei der Miete von Produkten</h2>
                    <h3>14. Allgemeines</h3>
                    <p>14.1 Die Bestimmungen in diesem Kapitel kommen zur Anwendung, wenn der Kunde Produkte der Vermflex mietet (nachfolgend als Mietobjekt beschrieben).</p>
                    <h3>15. Mietobjekt</h3>
                    <p>15.1 Die Vermflex überlässt dem Kunden die bestellten Mietobjekte lediglich zur Benützung in der Schweiz.</p>
                    <h3>16. Eigentum</h3>
                    <p>16.1 Das Mietobjekt samt Bestandteilen und Zubehör bleibt während der ganzen Mietdauer ausschliesslich Eigentum der Vermflex.</p>
                    <h3>17. Verwendung</h3>
                    <p>17.1 Ohne vorherige schriftliche Zustimmung der Vermflex dürfen keinerlei Änderungen am Mietobjekt vorgenommen werden.</p>
                    <p>
                        17.2 Betriebs- und Wartungsvorschriften sowie Weisungen betreffend sachgemässe Verwendung sind strikte einzuhalten. An dieser Stelle sei insbesondere explizit darauf hingewiesen, dass Regen, Schnee, Nebel sowie
                        grosse Temperaturunterschiede die Messqualität beeinträchtigen können. Falls Scanarbeiten unter diesen Bedingungen durchgeführt werden müssen (wenn immer möglich vermeiden), ist zudem besondere Sorgfalt geboten, um
                        Schäden am Mietobjekt zu vermeiden.
                    </p>
                    <p>17.3 Der Kunde ist nicht befugt, Dritten Rechte am Mietobjekt einzuräumen oder ihnen Rechte aus dem Mietvertrag abzutreten; insbesondere sind Untermiete oder Weiterverleihen des Mietobjekts untersagt.</p>
                    <h3>18. Mietzins</h3>
                    <p>18.1 Der vereinbarte Mietzins gilt für die vereinbarte Dauer bei der Bestellung.</p>
                    <p>18.2 Der Mietzins ist auch dann für die gesamte Mietdauer geschuldet, wenn das Mietobjekt vor Ablauf der vereinbarten Mietdauer zurückgegeben wird.</p>
                    <p>
                        18.3. Der guten Ordnung halber wird ausdrücklich darauf hingewiesen, dass der Mietzins auch dann für die gesamte Mietdauer geschuldet ist, wenn der Kunde vor Mietbeginn erklärt, er wolle das Mietobjekt nun doch nicht
                        in Anspruch nehmen. Erfolgt diese Erklärung mehr als fünf Arbeitstage vor Mietbeginn, sind nur 50% des Mietzinses geschuldet.
                    </p>
                    <p>18.4 Im vereinbarten Mietzins sind allfällige Transport- und Verpackungskosten nicht inbegriffen, sofern nicht etwas anderes vereinbart wurde.</p>
                    <h3>19. Verzug</h3>
                    <p>
                        19.1 Befindet sich der Kunde mit einer Zahlung im Rückstand, und kommt er der Aufforderung der Vermflex, innerhalb einer Frist von 10 Tagen den rückständigen Mietzins zu bezahlen, nicht nach, so wird der Mietvertrag
                        mit Ablauf dieser Frist aufgelöst (ausser der Mietvertrag sei bereits vorher beendet worden). Spricht die Vermflex den Rücktritt vom Vertrag aus, so hat der Kunde das Mietobjekt unverzüglich der Vermflex
                        zurückzusenden, wobei die Transportkosten für die Rücksendung sowie allfällige weitere damit verbundene Spesen zu Lasten des Kunden gehen. Der Kunde bleibt zur Bezahlung des Mietzinses bis zum Ende der vereinbarten
                        Mietdauer verpflichtet; die Vermflex muss sich jedoch anrechnen lassen, was sie durch anderweitige Verwendung des Mietobjektes während der Mietdauer erlangt. Im Übrigen sei auf Ziff. 4.1 verwiesen.
                    </p>
                    <h3>20. Mietbeginn</h3>
                    <p>20.1 Im Rahmen der Bestellung gibt der Kunde an, ob er das Mietobjekt abholen möchte oder ob es per Post versendet werden soll.</p>
                    <p>20.2 Wählt der Kunde die Abholung, gilt: Das Mietobjekt kann bei der Vermflex, Bionstrasse 3, 9015 St. Gallen, ab dem ersten Miettag ab 8 Uhr persönlich abgeholt werden.</p>
                    <p>
                        20.3. Wählt der Kunde den Postversand, gilt: Das Mietobjekt wird durch die Post mittels Mond-Express bis spätestens um 9 Uhr des ersten Miettages geliefert. (Die Rückversandetikette wird diesfalls von der Vermflex
                        mitgeliefert; siehe dazu unten Ziff. 23.1)
                    </p>
                    <p>20.4 Die Gefahr geht auf den Kunden über, sobald das Mietobjekt dem Kunden zur Verfügung gestellt wird.</p>
                    <h3>21. Pflichten der Vermflex</h3>
                    <p>
                        21.1 Mängel in der ordnungsgemässen Gebrauchsbereitschaft bei der Auslieferung des Mietobjektes hat die Vermflex so rasch wie möglich auf ihre Kosten zu beheben. Gelingt es der Vermflex nicht, die vertragsgemässe
                        Gebrauchsbereitschaft des Mietobjektes trotz entsprechender schriftlicher Mängelrüge des Kunden innert nützlicher Frist herbeizuführen oder aber gleichwertigen Ersatz zu liefern, so ist der Kunde berechtigt, vom
                        Mietvertrag zurückzutreten.
                    </p>
                    <p>
                        21.2 Treten am Mietobjekt während der Mietdauer von Vermflex zu vertretende Mängel auf, welche dessen vertragsgemässen Gebrauch beeinträchtigen oder verunmöglichen, so ist die Vermflex nach entsprechender
                        schriftlicher Anzeige des Kunden verpflichtet, die gemeinsam festgestellten Mängel entweder innert nützlicher Frist auf ihre Kosten zu beheben oder aber gleichwertigen Ersatz zu leisten. Kommt die Vermflex dieser
                        Pflicht nicht nach, so ist der Kunde berechtigt, im Falle der Unmöglichkeit der weiteren Benützung des Mietobjektes vom Vertrag zurückzutreten und im Falle einer längeren Beeinträchtigung im vertragsgemässen Gebrauch
                        des Mietobjektes für die Dauer der Beeinträchtigung einen angemessenen Abzug vom Mietzins zu tätigen.
                    </p>
                    <h3>22. Pflichten des Kunden</h3>
                    <p>
                        22.1 Der Kunde hat das Mietobjekt sofort nach Erhalt zu prüfen und allfällige Mängel der Vermflex unverzüglich schriftlich anzuzeigen, andernfalls das Mietobjekt als vom Kunden genehmigt gilt. Die Rüge von Mängeln,
                        die keinen Betriebsunterbruch zur Folge haben, entheben den Kunden nicht von der Pflicht zur termingerechten Bezahlung des Mietzinses.
                    </p>
                    <p>
                        22.2 Der Kunde hat das Mietobjekt mit aller Sorgfalt zu behandeln, zu bedienen und zu warten. Der Kunde ist verpflichtet und dafür verantwortlich, dass der Betreiber des Gerätes instruiert ist. Nur instruierte
                        Personen dürfen das Gerät benutzen.
                    </p>
                    <p>
                        22.3 Funktioniert das Mietobjekt nach Ansicht des Kunden nicht ordnungsgemäss, hat er die Vermflex sofort zu benachrichtigen. Die Benützung des Mietobjektes ist durch den Kunden so lange einzustellen, bis die Störung
                        durch die Vermflex überprüft und gegebenenfalls die notwendige Reparatur vorgenommen ist. Der Kunde ist insbesondere auch verpflichtet, die Vermflex über Probleme am Mietobjekt zu informieren, die gegen Ende der
                        Mietdauer entstehen. Unterlässt der Kunde eine entsprechende Meldung, kann er insbesondere auch wegen entgangenem Gewinn (z.B. im Zusammenhang mit der nächsten Vermietung) haftbar werden.
                    </p>
                    <p>
                        22.4 Die Vermflex ist berechtigt, das Mietobjekt jederzeit nach vorheriger Vereinbarung mit dem Kunden auf seinen Zustand zu untersuchen oder untersuchen zu lassen. Weisungen der Vermflex für Bedienung, Überwachung,
                        Unterhalt und Wartung des Mietobjektes hat der Kunde strikt zu befolgen.
                    </p>
                    <p>
                        22.5 Der Kunde darf nur mit schriftlicher Zustimmung der Vermflex Reparaturen selbst vornehmen oder durch einen Dritten ausführen lassen, ansonsten er die Kosten und die Verantwortung selbst zu tragen hat. Überdies
                        haftet er für sämtliche direkten oder indirekten Schäden aus unsachgemässer Reparaturarbeit.
                    </p>
                    <p>
                        22.6 Reparaturen, hervorgerufen durch Gewalt, Unfallschäden, unsachgemässe Behandlung/Bedienung und Wartung, hat der Kunde zu tragen, sofern es sich nicht um Kosten für die Behebung eines von der Vermflex zu
                        vertretenen Mangels handelt, der vom Kunden rechtzeitig und ordnungsgemäss gerügt worden ist. Die durch normalen Betrieb und Abnützung des Mietobjektes bewirkten Reparaturen und Revisionen sowie die durch
                        vertragsgemässen Gebrauch entstandene Wertverminderung gehen zu Lasten der Vermflex.
                    </p>
                    <p>
                        22.7 Der Kunde haftet vom Zeitpunkt des Gefahrenübergangs bis zum Eintreffen des Mietobjektes bei der Vermflex oder dem von ihr bezeichneten Ort anlässlich der Rückgabe für jeden Verlust und/oder jede Beschädigung
                        des Mietobjektes und die im Zusammenhang damit stehenden Kosten ohne Rücksicht darauf, ob sie durch sein Verschulden oder das seiner Hilfspersonen, durch Verschulden Dritter, durch Zufall oder höhere Gewalt
                        verursacht wurde.
                    </p>
                    <h3>23. Beendigung der Miete</h3>
                    <p>23.1 Der Kunde ist verpflichtet, das Mietobjekt rechtzeitig und in gereinigtem und gebrauchsfähigem Zustand zurückzugeben.</p>
                    <p>
                        23.2 Der Kunde muss das Mietobjekt spätestens am letzten Miettag bis 19 Uhr bei der Vermflex, Bionstrasse 3, 9015 St. Gallen, zurückgeben. Wurde Postversand vereinbart (vgl. oben Ziffer 20.3), muss das Mietobjekt am
                        letzten Miettag bis zum Aufgabeschluss (i.d.R. spätestens 18 Uhr) an einer beliebigen Poststelle aufgegeben werden.
                    </p>
                    <p>23.3. Werden die oben erwähnten Fristen nicht eingehalten, wird ein zusätzlicher Miettag verrechnet.</p>
                    <p>
                        23.4 Verletzt der Kunde vertragliche Verpflichtungen, kann die Vermflex vorzeitig vom Vertrag zurücktreten. Beendet die Vermflex den Vertrag durch ausserordentliche Kündigung, kann sie das Mietobjekt auf Kosten des
                        Kunden zurücknehmen. Der Kunde bleibt überdies zur Leistung von Schadenersatz verpflichtet.
                    </p>
                    <p>23.5 Allfällig erforderliche Instandstellungsarbeiten erfolgen auf Kosten des Kunden.</p>
                    <p>23.6 Der Vermflex bleibt die Geltendmachung weiterer Schadenersatzansprüche vorbehalten. Der Kunde haftet für das Mietobjekt bis zum Zeitpunkt, in dem dieses bei der Vermflex eintrifft.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "AGB",
  mounted() {
    document.title = "AGB - scanner4you";
  },
};
</script>