<template>
  <div aria-live="polite" aria-atomic="true" class="position-fixed bottom-0 end-0 p-3" style="z-index: 600">
    <div class="toast" style="">
      <div class="toast-header">
        <strong class="mr-auto">{{ title }}</strong>
      </div>
      <div class="toast-body">
        <div class="toast-text">{{ body }}</div>
        <div class="bundle-buttons">
          <button @click="hideToast()" type="button" class="close-modal" data-dismiss="toast"
            aria-label="Close">Schliessen</button>
          <router-link to="/cart" class="to-cart">Warenkorb</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    title: String,
    body: String,
    color: String,
  },
  data() {
    return {
      item: this.initialItem,
    };
  },
  methods: {
    hideToast() {
      $('.toast').toast('hide');
    },
  },
};
</script>
