<template>
  <div class="col-sm-12 col-md-4 col-lg-3">
    <div class="product-item mb-4">
      <router-link v-bind:to="product.get_absolute_url" class="buy-product">
        <div class="product-body accessory-body d-flex flex-column justify-content-center">
          <h2 class="product-title text-center mb-2">{{ product.name }}</h2>
          <div class="product-thumbnail">
            <figure class="image mt-2 mb-2">
              <img
                v-if="product.get_thumbnail == 'default'"
                src="../assets/images/accessory.png"
              />
              <img v-else v-bind:src="product.get_thumbnail" />
            </figure>
          </div>
        </div>
      </router-link>
      <div class="product-qinformation">
        <div class="product-price"><span v-if="product.price"></span>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF', trailingZeroDisplay: 'stripIfInteger'}).format(product.price)}}</div>
        <button class="product-buttons" @click="addToCart">
          <i class="icon-cart"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductBox_buy",
  props: {
    product: Object,
  },
  methods: {
    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }
      const item = {
        product: this.product,
        quantity: this.quantity,
      };
      this.$store.commit("addToCart", item);
      this.$emit('toasted', "Das Zubehör wurde dem Warenkorb hinzugefügt.");
    },
  },
};
</script>

<style>
</style>