<template>
  <div v-if="loaded">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="page-title text-center">Account bearbeiten</h1>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <Menu />
          </div>
          <div class="col-sm-12 col-md-6">
            <fieldset>
              <div class="row g-3">
                <div class="col-sm-12 col-md-6">
                  <label class="form-label">Vorname*</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="first_name" autocomplete="given-name" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label class="form-label">Name *</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="last_name" autocomplete="family-name" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label class="form-label">Firma *</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="company" autocomplete="organization" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label class="form-label">Branche *</label>
                  <div class="control">
                    <select class="form-select" v-model="sector" aria-label="Default select example">
                      <option selected disabled>Auswählen</option>
                      <option value="Architekturbau">Architekturbau</option>
                      <option value="Ingenieursbau">Ingenieursbau</option>
                      <option value="Schlosser- und Metallbau">Schlosser und Metallbau</option>
                      <option value="Elektro- und Anlagebau">Elektro- und Anlagebau</option>
                      <option value="Sanitärgewerbe">Sanitärgewerbe</option>
                      <option value="Maurergewerbe">Maurergewerbe</option>
                      <option value="Malergewerbe">Malergewerbe</option>
                      <option value="Raumplanung">Raumplanung</option>
                      <option value="Andere">Andere</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12">
                  <label class="form-label">Adresse *</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="address" autocomplete="street-address" />
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="form-label">PLZ*</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="postalcode" autocomplete="postal-code" />
                  </div>
                </div>
                <div class="col-md-9">
                  <label class="form-label">Ort*</label>
                  <div class="control">
                    <input type="email" class="form-input" v-model="city" autocomplete="address-level2" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label class="form-label">Telefon*</label>
                  <div class="control">
                    <input type="text" class="form-input" v-model="phone" autocomplete="tel"
                      placeholder="071 234 56 78" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label class="form-label">E-Mail*</label>
                  <div class="control">
                    <input type="email" class="form-input" id="disabledTextInput" v-model="email" autocomplete="email"
                      disabled />
                  </div>
                </div>
                <div class="col-sm-12">
                  <button class="btn-standard btn-cart" @click="submitForm">
                    Speichern
                  </button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import Menu from "@/components/AccountMenu.vue";
export default {
  name: "MyAccount",
  components: {
    Spinner,
    Menu
  },
  data() {
    return {
      loaded: false,
      company: "",
      sector: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      postalcode: "",
      city: "",
    };
  },
  mounted() {
    document.title = "Account bearbeiten - scanner4you";
    this.getContact();
  },
  methods: {
    async getContact() {
      await axios
        .get("/api/v1/profile/")
        .then((response) => {
          this.loaded = true
          this.company = response.data["company"];
          this.sector = response.data["sector"];
          this.first_name = response.data["first_name"];
          this.last_name = response.data["last_name"];
          this.email = response.data["email"];
          this.phone = response.data["phone"];
          this.address = response.data["address"];
          this.postalcode = response.data["postalcode"];
          this.city = response.data["city"];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async updateContact() {
      let data = {
        // billing address
        company: this.company,
        sector: this.sector,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        address: this.address,
        postalcode: this.postalcode,
        city: this.city,
        phone: this.phone,
      };
      await axios
        .put("/api/v1/profile/", data)
        .catch((error) => {
          this.errors.push("Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00).");
          console.log(error);
        });
    },
    submitForm() {
      this.errors = [];
      if (this.company === "") {
        this.errors.push("Bitte Firma eingeben");
      }
      if (this.sector === "") {
        this.errors.push("Bitte Branche auswählen");
      }
      if (this.first_name === "") {
        this.errors.push("Bitte Vornamen eingeben");
      }
      if (this.last_name === "") {
        this.errors.push("Bitte Namen eingeben");
      }
      if (this.address === "") {
        this.errors.push("Bitte Adresse eingeben");
      }
      if (this.postalcode === "") {
        this.errors.push("Bitte Postleitzahl eingeben");
      }
      if (this.city === "") {
        this.errors.push("Bitte Ort eingeben");
      }
      if (this.phone === "") {
        this.errors.push("Bitte Telefonnummer eingeben");
      }
      if (this.email === "") {
        this.errors.push("Bitte E-Mail Adresse eingeben");
      }
      if (this.errors.length == 0) {
        this.updateContact();
      }
      this.$router.push("/account/");
    },
  },
};
</script>

<style>
.account-menu-item {
  cursor: pointer;
}
</style>