<template>
  <div v-if="loaded" class="container">
    <div class="row">
      <div class="col-12">
        <Breadcrumb class="row justify-content-center mt-4" :crumbs="crumbs" @selected="selected" />
        <h1 class="page-title text-center">Zubehör</h1>
        <div class="d-flex justify-content-sm-start justify-content-md-center model-chooser mb-4">
          <button v-bind:class="{ active: all }" class="select-product" @click="filterCategory('all')">Alle</button>
          <button v-bind:class="{ active: blk2go }" class="select-product" @click="filterCategory('blk-2-go')">BLK 2 GO</button>
          <button v-bind:class="{ active: blk360g2 }" class="select-product" @click="filterCategory('blk-360-g2')">BLK 360 G2</button>
          <button v-bind:class="{ active: blk3d }" class="select-product" @click="filterCategory('blk-3d')">BLK 3D</button>
          <button v-bind:class="{ active: rtc360 }" class="select-product" @click="filterCategory('rtc-360')">RTC 360</button>
        </div>
        <div class="row">
          <ProductBox_accessory
            v-for="product in orderedProducts"
            v-bind:key="product.id"
            v-bind:product="product"
            @toasted="showToast"
          />
          
        </div>
      </div>
    </div>
    <Toast
          v-bind:title="toasttitle"
          v-bind:body="toastbody"
        />
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>

<script>
import axios from "axios";
import Toast from "@/components/Toast.vue";
import Spinner from '@/components/Spinner.vue';
import ProductBox_accessory from "@/components/ProductBox_accessory";
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: "Category",
  components: {
    ProductBox_accessory,
    Toast,
    Breadcrumb,
    Spinner
  },
  data() {
    return {
      products: [],
      all: true,
      blk3d: false,
      blk360g2: false,
      bkl2go: false,
      rtc360: false,
      toasttitle: "scanner4you",
      toastbody: "",
      crumbs: ['Kaufen', 'Zubehör'],
      loaded: false
    };
  },
  mounted() {
    this.getCategory();
  },
  watch: {
    $route(to, from) {
      if (to.name === "Category") {
        this.getCategory();
      }
    },
  },
  computed: {
    orderedProducts: function () {
      return _.orderBy(this.products, 'order')
    }
  },
  methods: {
    showToast(value) {
      this.toastbody = value;
      $('.toast').toast('show');
    },
    async getCategory() {
      axios
        .get(`/api/v1/products/accessory/`)
        .then((response) => {
          this.products = response.data;
          this.loaded = true
        })
        .catch((error) => {
          console.log(error);
          this.toastbody = "Etwas ist schiefgelaufen. Bitte versuchen Sie es nochmals."
          $('.toast').toast('show');
        });
    },
    async filterCategory(category) {
      if (category === "all") {
        this.all = true
        this.blk3d = false;
        this.rtc360 = false;
        this.blk360g2 = false;
        this.blk2go = false;
      } else if (category === "blk-3d") {
        this.all = false
        this.blk3d = true;
        this.rtc360 = false;
        this.blk360g2 = false;
        this.blk2go = false;
      } else if (category === "rtc-360") {
        this.all = false
        this.blk3d = false;
        this.rtc360 = true;
        this.blk360g2 = false;
        this.blk2go = false;
      } else if (category === "blk-360-g2") {
        this.all = false
        this.blk3d = false;
        this.rtc360 = false;
        this.blk360g2 = true;
        this.blk2go = false;
      } else if (category === "blk-2-go") {
        this.all = false
        this.blk3d = false;
        this.rtc360 = false;
        this.blk360g2 = false;
        this.blk2go = true;
      }

      axios
        .get(`/api/v1/products/search/accessory/${category}`)
        .then((response) => {
          this.products = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.toastbody = "Etwas ist schiefgelaufen. Bitte versuchen Sie es nochmals."
          $('.toast').toast('show');
        });
    },
  },
};
</script>