import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import "bootstrap/dist/css/bootstrap.min.css"
import "/src/assets/styles/main.scss"



if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'http://127.0.0.1:8000/backend'
}
else {
    axios.defaults.baseURL = '/backend';
}

// Add a response interceptor
axios.interceptors.response.use(
    response => {
        // Any 2xx response comes here
        return response;
    }, 
    error => {
        // Any non-2xx response comes here
        if (error.response && error.response.status === 403) {
            // Dispatch the resetState mutation
            store.commit('resetState');
            // Redirect to the login page
            router.push('/log-in');            
        }
        return Promise.reject(error);
    }
);

createApp(App).use(store).use(router, axios).mount('#app')
