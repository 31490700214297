<template>
  <div class="row" v-if="loaded">
    <h2 class="title-addons">Empfohlene Zusatzleistungen</h2>
    <div class="col-md-6"
      v-for="addon in addons"
      v-bind:key="addon.id"
      v-bind:item="addon" >
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ addon.name }}</h5>
          <p class="card-text">{{ addon.description }} </p>
          <div class="d-flex justify-content-between align-items-center">
            <span class="cart-item-price">CHF {{ addon.price }}</span>
            <button class="btn btn-light" v-on:click="rent(addon)" >
              <i class="icon-cart"></i>
              In den Warenkorb
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CartAddons",
  data() {
    return {
      addons: {},
      loaded: false,
    };
  },
  mounted() {
    this.getAddons();
  },
  methods: {
    async getAddons() {
      const cartToken = this.$store.state.cartToken

      await axios
        .get(`/api/v1/products/addon`)
        .then((response) => {
          this.addons = response.data;
          console.log(this.addons);
        })
        .catch((error) => {
          console.log(error);
        });
      this.loaded = true;
    },
    rent(addonObject){
      const item = {
        product: addonObject,
        quantity: 1,
        daycount: 1,
        rentalstartdate: undefined,
        rentalenddate: undefined,
        delivery: {id:undefined},
        productservice: {id:undefined},
      };
      console.log(item);
      this.$store.commit("addToCart", item);
      this.$emit('updateCart');
    },
  },
};
</script>