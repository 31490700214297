<template>
  <div>
    <div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="page-title text-center">Mein Konto</h1>
          </div>
        </div>
        <div class="row logreg">
          <div class="col-sm-12 col-md-6">
            <div class="account-login">
              <h3>Passwort vergessen?</h3>
              <div v-if="success" class="">
                <p>Falls Ihre E-Mail Adresse bei uns registriert ist, haben wir Ihnen eine Nachricht gesendet. Bitte öffnen Sie den darin enthaltenen Link, um Ihr Passwort
                  zurücksetzen.</p>
              </div>
              <div v-else class="">
                <form @submit.prevent="submitForm">
                  <div>
                    <label class="form-label">E-Mail</label>
                    <div class="control">
                      <input type="text" class="form-input" v-model="email" />
                    </div>
                  </div>
                  <div class="notification is-danger" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">
                      {{ error }}
                    </p>
                  </div>
                  <div class="d-flex">
                    <button class="login">Zurücksetzen</button>
                    <button class="back"><router-link to="/sign-up">Login</router-link></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="account-register">
              <h3 class="mb-3">Ich habe noch kein Konto</h3>
              <div class="bold mb-2">
                Sie möchten ein Kundenkonto eröffnen und Kundenvorteile nutzen?
              </div>
              <button class="register">
                <router-link to="/sign-up">Jetzt registrieren</router-link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-log-in">
      <div class="columns">
        <div class="column is-4 is-offset-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "LogIn",
  data() {
    return {
      email: "",
      errors: [],
      success: 0,
    };
  },
  mounted() {
    document.title = "Passwort vergessen - scanner4you";
  },
  methods: {
    async submitForm() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      const formData = {
        email: this.email,
      };
      await axios
        .post("/api/v1/reset_user_password/", formData)
        .then((response) => {
          this.success = 1;
        })
        .catch((error) => {
          this.success = 1;
          /*
          if (error.response) {
              this.errors.push(error.response.data);
          } else {
            this.errors.push("Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00).");
            console.log(JSON.stringify(error));
          }
          */
        });
    },
  },
};
</script>