<template>
  <div v-if="loaded" class="container pt-6">
    <div class="row justify-content-center">
      <div class="col-12">
        <h1 class="page-title text-center">Laserscanner kaufen </h1>
      </div>
      <div class="col-6 ">
        <p class="page-lead text-center mb-5">Präzise Erfassung und schnelle Weiterverarbeitung von 3D-Punktwolken mit Laserscanner von Leica Geosystems.</p>
      </div>
    </div>
    <div class="row">
      <ProductBox_purchase v-for="product in products" v-bind:key="product.id" v-bind:product="product" />
      <ProductBox_purchase v-bind:key="zubehoer.id" v-bind:product="zubehoer" />
      <ProductBox_purchase v-bind:key="softwareservice.id" v-bind:product="softwareservice" />
    </div>
    <Toast v-bind:title="toasttitle" v-bind:body="toastbody" />
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>

<script>
import axios from "axios";
import Toast from "@/components/Toast.vue";
import ProductBox_purchase from "@/components/ProductBox_purchase";
import Breadcrumb from '@/components/Breadcrumb.vue';
import Spinner from "@/components/Spinner.vue";
import softwareservice_thumbnail from "../assets/images/software-services.png"

export default {
  name: "Category",
  components: {
    ProductBox_purchase,
    Breadcrumb,
    Spinner,
  },
  data() {
    return {
      products: [],
      crumbs: ['Kaufen'],
      zubehoer: {
        "id": 1,
        "name": "Zubehör",
        "get_absolute_url": "/kaufen/zubehoer/",
        "get_thumbnail": "default",
        "videoUrl": null
      },
      softwareservice: {
        "id": 2,
        "name": "Software & Services",
        "get_absolute_url": "/kaufen/software-service/",
        "get_thumbnail": softwareservice_thumbnail,
        "videoUrl": null
      },
      toasttitle: "scanner4you",
      toastbody: "",
      loaded: false,
    };
  },
  mounted() {
    document.title = "Kaufen - scanner4you";
    this.getCategory();
  },
  watch: {
    $route(to, from) {
      if (to.name === "Category") {
        this.getCategory();
      }
    },
  },
  methods: {
    async getCategory() {
      axios
        .get(`/api/v1/products/purchase/`)
        .then((response) => {
          this.products = response.data;
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
          this.toastbody = "Etwas ist schiefgelaufen. Bitte versuchen Sie es nochmals."
          $('.toast').toast('show');
        });
    },
  },
};
</script>
