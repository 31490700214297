<template>
  <div v-if="loaded" class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title text-center">Benutzerkonto</h1>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <Menu />
        </div>
        <div class="col-sm-12 col-md-6">
          <fieldset disabled>
            <div class="row g-3">
              <div class="col-sm-12 col-md-6">
                <label class="form-label">Vorname*</label>
                <div class="control">
                  <input type="text" class="form-input" v-model="first_name" autocomplete="given-name" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label class="form-label">Name *</label>
                <div class="control">
                  <input type="text" class="form-input" v-model="last_name" autocomplete="family-name" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label class="form-label">Firma *</label>
                <div class="control">
                  <input type="text" class="form-input" v-model="company" autocomplete="organization" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label class="form-label">Branche *</label>
                <div class="control">
                  <select class="form-select" v-model="sector" aria-label="Default select example">
                    <option selected disabled>Auswählen</option>
                    <option value="Architekturbau">Architekturbau</option>
                    <option value="Ingenieursbau">Ingenieursbau</option>
                    <option value="Schlosser- und Metallbau">Schlosser und Metallbau</option>
                    <option value="Elektro- und Anlagebau">Elektro- und Anlagebau</option>
                    <option value="Sanitärgewerbe">Sanitärgewerbe</option>
                    <option value="Maurergewerbe">Maurergewerbe</option>
                    <option value="Malergewerbe">Malergewerbe</option>
                    <option value="Raumplanung">Raumplanung</option>
                    <option value="Andere">Andere</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <label class="form-label">Adresse *</label>
                <div class="control">
                  <input type="text" class="form-input" v-model="address" autocomplete="street-address" />
                </div>
              </div>
              <div class="col-md-3">
                <label class="form-label">PLZ*</label>
                <div class="control">
                  <input type="text" class="form-input" v-model="postalcode" autocomplete="postal-code" />
                </div>
              </div>
              <div class="col-md-9">
                <label class="form-label">Ort*</label>
                <div class="control">
                  <input type="email" class="form-input" v-model="city" autocomplete="address-level2" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label class="form-label">Telefon*</label>
                <div class="control">
                  <input type="text" class="form-input" v-model="phone" autocomplete="tel"
                    placeholder="071 234 56 78" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label class="form-label">E-Mail*</label>
                <div class="control">
                  <input type="email" class="form-input" v-model="email" autocomplete="email" />
                </div>
              </div>
            </div>
          </fieldset>
          <router-link to="/account/edit">
            <button class="btn-standard btn-cart">
              Bearbeiten
            </button>
          </router-link>

        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import Menu from "@/components/AccountMenu.vue";
export default {
  name: "MyAccount",
  components: {
    Spinner,
    Menu
  },
  data() {
    return {
      loaded: false,
      company: "",
      sector: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      postalcode: "",
      city: "",
    };
  },
  mounted() {
    document.title = "Benutzerkonto - scanner4you";
    this.getContact();
  },
  methods: {
    async getContact() {
      await axios
        .get("/api/v1/profile/")
        .then((response) => {
          this.company = response.data["company"];
          this.sector = response.data["sector"];
          this.first_name = response.data["first_name"];
          this.last_name = response.data["last_name"];
          this.email = response.data["email"];
          this.phone = response.data["phone"];
          this.address = response.data["address"];
          this.postalcode = response.data["postalcode"];
          this.city = response.data["city"];
          this.loaded = true
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.account-menu-item {
  cursor: pointer;
}
</style>
