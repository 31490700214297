<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title text-center">Warenkorb</h1>
      </div>
    </div>
    <div v-if="loaded">
      <div v-if="cart.items.length" class="row">
        <div class="col-sm-12 col-md-9">
          <CartItem
            v-for="item in cart.items"
            v-bind:key="item.product.id"
            v-bind:item="item"
            v-on:removeFromCart="removeFromCart"
            @updateCart="getCart"
          />
          <CartAddons 
            @updateCart="getCart"/>
        </div>
        <div class="col-lg-3">
          <h3>Zusammenfassung der Bestellung</h3>
          <div class="cart-items-qty">{{ cart.total_quantity }} Artikel</div>
          <hr class="cart-line" />
          <div class="cart-detail">
            <span>Total exkl. MwSt.</span>
            <span>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.total_price) }}</span>
          </div>
          <div class="cart-detail">
            <span>8.1% MwSt.</span>
            <span>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.total_vat) }}</span>
          </div>
          <hr class="cart-line" />
          <div class="cart-detail bold">
            <span>Total inkl. MwSt.</span>
            <span>{{ new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart.total_price + cart.total_vat) }}</span>
          </div>
          <router-link to="/cart/checkout">
            <button class="btn-standard btn-cart">Zur Kasse</button>
          </router-link>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-sm-12 text-center">
          <p>
            Ihr Warenkorb ist leer. Noch nichts passendes gefunden?<br>
          Oder haben Sie Fragen? Gerne unterstützen wir Sie auch persönlich.
          </p>
          <div class="d-flex justify-content-center">
            <a href="tel:+41 71 311 44 00" class="contact-us-icon">
              <i class="fs-1 bi bi-telephone"></i>
            </a>
            <a href="mailto:info@scanner4you.ch" class="contact-us-icon">
              <i class="fs-1 bi bi-envelope-at"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Spinner/>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CartItem from "@/components/CartItem.vue";
import CartAddons from "@/components/CartAddons.vue";
import Spinner from "@/components/Spinner.vue";
export default {
  name: "Cart",
  components: {
    CartItem,
    Spinner,
    CartAddons
  },
  data() {
    return {
      cart: {},
      loaded: false,
    };
  },
  mounted() {
    document.title = "Warenkorb - scanner4you";
    this.getCart();
  },
  methods: {
    async getCart() {
      const cartToken = this.$store.state.cartToken

      await axios
        .get(`/api/v1/cart/${cartToken}`)
        .then((response) => {
          this.cart = response.data;
          console.log(this.cart);
        })
        .catch((error) => {
          console.log(error);
        });
      this.loaded = true;
      this.$store.commit("updateCartCount");
    },
  },
};
</script>
