<template>
  <div class="schulen">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="page-title">Impressum</h1>
          </div>
        </div>
        <div class="col-lg-8">
          <strong>Kontakt-Adresse</strong>
          <p>
            Vermflex GmbH<br>
            Bionstrasse 3<br>
            9015 St. Gallen<br>
            Schweiz<br>
            E-Mail: info@vermflex.ch<br>
          </p>
          <strong>Vertretungsberechtigte Personen</strong>
          <p>
            Adrian Keller<br>
            Roman Gnägi<br>
          </p>
          <strong>Handelsregister-Eintrag</strong>
          <p>
            Eingetragener Firmenname: Vermflex GmbH <br>
            Handelsregister Nr: CHE-476.934.919<br>
          </p>
          <strong>Mehrwertsteuer-Nummer</strong>
          <p>
            CHE-476.934.919 MWST
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = "Impressum - scanner4you";
  }
}
</script>