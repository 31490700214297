<template>
    <ul>
        <li class="account-menu-item">
            <a href="/order" class="account-menu-item-link">Meine Bestellungen</a>
        </li>
        <li class="account-menu-item">
            <a href="/account" class="account-menu-item-link">Mein Benutzerkonto</a>
        </li>
        <li class="account-menu-item">
            <a @click="logout()" class="account-menu-item-link">
                <i class="icon-logout"></i>Abmelden</a>
        </li>
    </ul>
</template>
<script>
import axios from 'axios';
export default {
    methods: {
      logout() {
        axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("userid");
        this.$store.commit("removeToken");
        this.$router.push("/");
      },
    },
  };
  </script>