<template>
  <div v-if="loaded">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <Breadcrumb class="row justify-content-center mt-4" :crumbs="crumbs" @selected="selected" />
        </div>
      </div>
    </div>
    <div class="container product-view">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <figure class="image mb-6">
            <carousel v-bind:product="product"> </carousel>
          </figure>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="row mb-4">
            <div class="col-sm-12 col-md-9">
              <h1 class="product-title">{{ product.name }}</h1>
              <div v-html="product.description" class="product-description"></div>
              <div class="product-specification">
                <div v-if="product.fact_laserreach" class="fact">
                  <img class="product-detail-icon" src="../assets/icons/reach.svg" />
                  {{ product.fact_laserreach }}
                </div>
                <div v-if="product.fact_laseraccuracy" class="fact">
                  <img class="product-detail-icon" src="../assets/icons/accuracy.svg" />
                  {{ product.fact_laseraccuracy }}
                </div>
                <div v-if="product.fact_speed" class="fact">
                  <img class="product-detail-icon" src="../assets/icons/speed.svg" />
                  {{ product.fact_speed }}
                </div>
                <div v-if="product.fact_battery" class="fact">
                  <img class="product-detail-icon" src="../assets/icons/battery.svg" />
                  {{ product.fact_battery }}
                </div>
              </div>
              <div class="product-price">{{
                new Intl.NumberFormat('de-CH', {
                  style: 'currency', currency: 'CHF',
                  trailingZeroDisplay: 'stripIfInteger'
                }).format(price)
              }}</div>
              <div class="product-subprice">exkl. MwSt. inkl. Lieferung</div>
              <div class="product-subprice">Lieferung sofern vorrätig: 3-5 Arbeitstage
                <Tooltip />
              </div>
            </div>
          </div>
          <div class="row g-3 packages mb-4">
            <input type="hidden" class="input" min="1" v-model="quantity" />
            <div class="col-3">
              <input type="radio" :id="product.id" value="-1" v-model="selectedpackage" />
              <label :for="product.id" @click="setPriceAndDeliveryContent(-1)">Basic</label>
            </div>
            <div v-for="(item, index) in product.packages" v-bind:key="item.id" class="col-3">
              <input type="radio" :id="item.id" :value="index" v-model="selectedpackage" />
              <label :for="item.id" @click="setPriceAndDeliveryContent(index)">{{ item.name }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-9">
              <div v-if="deliverycontent" class="product-delivery-content">
                <div class="product-delivery-content-title">Lieferumfang</div>
                <div v-html="deliverycontent" class="package-box"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="bundle-buttons">
                <button class="add-to-cart" @click="addToCart">
                  <i class="icon-cart"></i>In den Warenkorb
                </button>
                <button class="button-dark">
                  <a href="tel:+41 71 311 44 00"><i class="icon-phone"></i>Wir helfen gerne</a>
                </button>
              </div>
            </div>
          </div>
          <div class="row product-more-information">
            <div class="col-12">
              <h4>Weitere Informationen</h4>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item" v-if="product.accordionheader">
                  <h2 class="accordion-header" id="flush-accordion-1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-accordion-content-1" aria-expanded="false"
                      aria-controls="flush-accordion-content-1">
                      {{ product.accordionheader }}
                    </button>
                  </h2>
                  <div id="flush-accordion-content-1" class="accordion-collapse collapse"
                    aria-labelledby="flush-accordion-1" data-bs-parent="#accordionFlushExample">
                    <div v-html="product.accordioncontent" class="accordion-body"></div>
                  </div>
                </div>
                <div class="accordion-item" v-if="product.accordionheader2">
                  <h2 class="accordion-header" id="flush-accordion-2">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-accordion-content-2" aria-expanded="false"
                      aria-controls="flush-accordion-content-2">
                      {{ product.accordionheader2 }}
                    </button>
                  </h2>
                  <div id="flush-accordion-content-2" class="accordion-collapse collapse"
                    aria-labelledby="flush-accordion-2" data-bs-parent="#accordionFlushExample">
                    <div v-html="product.accordioncontent2" class="accordion-body"></div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-zubehoer" aria-expanded="false" aria-controls="flush-zubehoer">
                      Zubehör
                    </button>
                  </h2>
                  <div id="flush-zubehoer" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body attach-accessories">
                      <div v-for="accessory in product.accessoryitems" v-bind:key="accessory.id"
                        class="d-flex flex-row attach-accessory">
                        <router-link v-bind:to="accessory.get_absolute_url">
                          <div class="attach-accessory-title">
                            {{ accessory.name }}
                          </div>
                        </router-link>
                        <button class="btn-bg-none" @click="addAccessoryToCart(accessory)">
                          <i class="icon-cart"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-software" aria-expanded="false" aria-controls="flush-software">
                      Software & Services
                    </button>
                  </h2>
                  <div id="flush-software" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body attach-accessories">
                      <div v-for="softwareservice in product.softwareserviceitems" v-bind:key="softwareservice.id"
                        class="d-flex flex-row attach-softwareservice">
                        <router-link v-bind:to="softwareservice.get_absolute_url">
                          <div class="attach-softwareservice-title">
                            {{ softwareservice.name }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item" v-if="product.get_document">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-downloads" aria-expanded="false" aria-controls="flush-downloads">
                      Downloads
                    </button>
                  </h2>
                  <div id="flush-downloads" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body attach-accessories">
                      <div v-if="product.get_document" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_document" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.document_title }}
                        </a>
                      </div>
                      <div v-if="product.get_document2" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_document2" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.document_title2 }}
                        </a>
                      </div>
                      <div v-if="product.get_document3" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_document3" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.document_title3 }}
                        </a>
                      </div>
                      <div v-if="product.get_document4" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_document4" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.document_title4 }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item" v-if="product.get_sampledata">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-sampledata" aria-expanded="false" aria-controls="flush-sampledata">
                      Musterdaten
                    </button>
                  </h2>
                  <div id="flush-sampledata" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body attach-accessories">
                      <div v-if="product.get_sampledata" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_sampledata" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.sampledata_title }}
                        </a>
                      </div>
                      <div v-if="product.get_sampledata2" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_sampledata2" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.sampledata_title2 }}
                        </a>
                      </div>
                      <div v-if="product.get_sampledata3" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_sampledata3" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.sampledata_title3 }}
                        </a>
                      </div>
                      <div v-if="product.get_sampledata4" class="d-flex flex-row attach-accessory product-downloads">
                        <a v-bind:href="product.get_sampledata4" target="_blank">
                          <img class="product-detail-icon" src="../assets/icons/download.svg" />
                          {{ product.sampledata_title4 }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item" v-if="product.accordionheader3">
                  <h2 class="accordion-header" id="flush-accordion-3">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-accordion-content-3" aria-expanded="false"
                      aria-controls="flush-accordion-content-3">
                      {{ product.accordionheader3 }}
                    </button>
                  </h2>
                  <div id="flush-accordion-content-3" class="accordion-collapse collapse"
                    aria-labelledby="flush-accordion-3" data-bs-parent="#accordionFlushExample">
                    <div v-html="product.accordioncontent3" class="accordion-body"></div>
                  </div>
                </div>
                <div class="accordion-item" v-if="product.accordionheader4">
                  <h2 class="accordion-header" id="flush-accordion-4">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-accordion-content-4" aria-expanded="false"
                      aria-controls="flush-accordion-content-4">
                      {{ product.accordionheader4 }}
                    </button>
                  </h2>
                  <div id="flush-accordion-content-4" class="accordion-collapse collapse"
                    aria-labelledby="flush-accordion-4" data-bs-parent="#accordionFlushExample">
                    <div v-html="product.accordioncontent4" class="accordion-body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast v-bind:title="toasttitle" v-bind:body="toastbody" />
    </div>
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import Carousel from "@/components/Carousel.vue";
import Tooltip from "@/components/Tooltip.vue";
import Toast from "@/components/Toast.vue";
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: "Product",
  data() {
    return {
      loaded: false,
      crumbs: ['Kaufen'],
      product: {},
      selectedpackage: -1,
      quantity: 1,
      visibleSlide: 0,
      direction: "left",
      toasttitle: "scanner4you",
      toastbody: "",
      price: 0,
      deliverycontent: "",
    };
  },
  components: {
    Spinner,
    Carousel,
    Tooltip,
    Toast,
    Breadcrumb,
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      const category_slug = this.$route.params.category_slug;
      const product_slug = this.$route.params.product_slug;

      await axios
        .get(`/api/v1/products/purchase/${product_slug}`)
        .then((response) => {
          this.product = response.data;
          this.crumbs.push(this.product.name)
          this.deliverycontent = this.product.deliverycontent;
          this.price = this.product.price
          this.loaded = true
          document.title = this.product.name + " - scanner4you";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addAccessoryToCart(accessory) {
      if (isNaN(accessory.quantity) || accessory.quantity < 1) {
        accessory.quantity = 1;
      }
      const item = {
        product: accessory,
        quantity: accessory.quantity,
      };
      this.$store.commit("addToCart", item);
      this.toastbody = "Das Zubehör wurde dem Warenkorb hinzugefügt."
      $('.toast').toast('show');
    },
    setPriceAndDeliveryContent(index) {
      let selector;
      if (index === -1) {
        selector = this.product;
      } else {
        selector = this.product.packages[index];
      }
      this.deliverycontent = selector.deliverycontent;
      this.price = selector.price;
    },
    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }
      let selected;
      if (this.selectedpackage === -1) {
        selected = this.product;
      } else {
        selected = this.product.packages[this.selectedpackage];
      }
      const item = {
        mainProduct: this.product.name,
        product: selected,
        quantity: this.quantity,
      };
      this.$store.commit("addToCart", item);
      this.toastbody = "Der Artikel wurde dem Warenkorb hinzugefügt."
      $('.toast').toast('show');
    },
  },
};
</script>
