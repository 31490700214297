<template>
  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
    <router-link v-bind:to="product.get_absolute_url" class="buy-product">
      <div class="product-item">
        <div class="product-body rental">
          <h2 class="product-title text-center mb-1">{{ product.name }}</h2>
          <div class="product-subtitle text-center mb-2">{{ product.subtitle }}</div>
          <div class="product-thumbnail">
            <figure class="image mt-2 mb-2">
              <img :src="product.get_thumbnail" />
            </figure>
          </div>
          <div class="row">
            <div class="col">
              <div class="product-price text-center"> <span>ab</span>
                {{ new Intl.NumberFormat('de-CH', {
      style: 'currency', currency: 'CHF', trailingZeroDisplay:
        'stripIfInteger'
    }).format(product.price) }}/ Tag
              </div>
              <div class="product-information text-center">inkl. 3D-Punktwolke
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductBox_rent",
  props: {
    product: Object,
  },
};
</script>

<style>
a:link {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

a:active {
  text-decoration: none;
  color: black;
}
</style>